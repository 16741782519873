.enabling-churches {
  text-align: center;

  &__container {
    @media (max-width: 800px){
      padding: 20px;
    }
  }

  &__title {
    h3 {
      font-family: $playfair;
      color: $eie-green;
      font-size: 3em;
      margin-bottom: 40px;

      @media (max-width: 425px) {
        font-size: 2.2em;
      }
    }
  }

  &__intro {
    max-width: 650px;
    margin: auto;

    p {
      font-family: $header-font;
      font-size: 1.3em;
    }
  }

  &__diagram {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 650px;
    height: 500px;
    margin: auto;

    @media (max-width: 750px) {
      width: 500px;
      height: 385px;
    }

    @media (max-width: 550px) {
      width: 100%;
      height: 300px;
      margin: 0;
    }

    @media (max-width: 400px) {
      width: 100%;
      height: 250px;
      margin: 0;
    }

    @media (max-width: 400px) {
      width: 100%;
      height: 250px;
      margin: 0;
    }
  }

  &__outro {
    h3 {
      margin-top: 40px;
      color: $eie-grey;

      @media (max-width: 750px) {
        font-size: 1.5em;
      }

      @media (max-width: 550px) {
        font-size: 1.3em;
      }
    }
  }
}