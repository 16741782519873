.page-title-quote {
  background: $eie-dark;
  padding: 0 !important;
  overflow: auto;

  &__header {
    font-family: $playfair;
    color: $white;
    font-size: 4.25em;
    text-align: right;
    margin: 0;

    &--inspiring {
      font-size: 5.85em;
    }

    &--equipping {
      font-size: 5.25em;
      margin: 0 0 60px;
    }

    @media(max-width: 1350px) {
      font-size: 3.5em;

      &--inspiring {
        font-size: 4.8em;
      }

      &--equipping {
        font-size: 4.3em;
      }
    }

    @media(max-width: 1140px) {
      font-size: 3em;

      &--inspiring {
        font-size: 4.1em;
      }

      &--equipping {
        font-size: 3.65em;
      }
    }

    @media(max-width: 900px) {
      font-size: 2.2em;

      &--inspiring {
        font-size: 3em;
      }

      &--equipping {
        font-size: 2.7em;
      }
    }

    @media(max-width: 800px) {
      font-size: 3.5em;

      &--inspiring {
        font-size: 4.8em;
      }

      &--equipping {
        font-size: 4.3em;
      }
    }

    @media (max-width: 620px) {
      text-align: left;
    }

    @media(max-width: 500px) {
      font-size: 2.6em;

      &--inspiring {
        font-size: 3.55em;
      }

      &--equipping {
        font-size: 3.2em;
      }
    }

    @media(max-width: 425px) {
      font-size: 2em;

      &--inspiring {
        font-size: 2.75em;
      }

      &--equipping {
        font-size: 2.5em;
      }
    }

    &-container {
      background: $eie-light;
      @include grid-column(6);
      width: 50%;
      display: inline-block;
      vertical-align: top;
      margin: 0;
      padding: 120px 160px 120px 0;
      position: relative;

      @media (max-width: 1140px) {
        padding: 120px 120px 120px 0;
      }

      @media (max-width: 800px) {
        @include grid-column(12);
        padding: 120px 200px 120px 0;
      }

      @media (max-width: 620px) {
        padding: 120px 70px 120px;
      }

      @media (max-width: 500px) {
        padding: 80px 60px;
      }
    }
  }

  &__image {
    height: 120px;
    width: 120px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    border-radius: 50%;

    @media (max-width: 1350px) {
      height: 80px;
      width: 80px;
    }

    @media (max-width: 800px) {
      height: 120px;
      width: 120px;
      vertical-align: top;
      display: inline-block;
    }
  }

  &__subtext-container {
    @include grid-column(6);
    width: 50%;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 120px 80px;
    position: relative;

    @media (max-width: 900px) {
      padding: 115px 40px 100px;
    }

    @media (max-width: 800px) {
      @include grid-column(12);
      padding: 120px 100px;
    }

    @media (max-width: 620px){
      padding: 80px 60px;
    }
  }

  &__subtitle, &__quoter {
    color: $white;
    margin: 0;
    font-family: $header-font;
    max-width: 55%;
    line-height: 1.5;

    @media (max-width: 1600px) {
      max-width: 70%;
    }

    @media (max-width: 1350px) {
      max-width: 100%;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 800px) {
      width: 70%;
      margin: 0 0 0 30px;
      vertical-align: top;
      display: inline-block;
    }

    @media (max-width: 720px) {
      width: 100%;
      margin: 0;
      display: block;
    }
  }

  &__subtitle {
    font-size: 1.4em;

    @media (max-width: 1140px) {
      font-size: 1.2em;
    }

    @media (max-width: 1030px) {
      font-size: 1em;
    }

    @media (max-width: 800px) {
      font-size: 1.5em;
    }
  }

  &__quoter {
    font-size: 1.2em;
    margin-top: 10px;

    @media (max-width: 1140px) {
      font-size: 1.1em;
    }

    @media (max-width: 1030px) {
      font-size: 1em;
    }

    @media (max-width: 800px) {
      margin-left: 150px;
      font-size: 1.2em
    }

    @media (max-width: 720px) {
      margin-left: 0;
    }

    @media (max-width: 620px) {
      margin-left: 0;
    }
  }

  &__underline {
    height: 10px;
    width: 83%;
    background: #ba558d;
    display: block;
    position: absolute;
    left: 0;
    bottom: 110px;

    @media(max-width: 1350px) {
      width: 75.5%;
    }

    @media(max-width: 800px) {
      width: 74.5%;
    }

    @media (max-width: 620px){
      width: 390px;
    }

    @media (max-width: 500px) {
      bottom: 85px;
      width: 60%;
    }

    @media (max-width: 425px) {
      bottom: 85px;
    }
  }

}