.event-popup {
  background-color: $white;
  padding: $base-space-unit;
  max-width: 830px;
  margin: 0 auto;
  position: fixed;
  top: $base-space-unit;
  left: 0;
  right: 0;
  display: none;
  z-index: 2000;
  overflow-y: auto;

  &__date {
    font-size: 66px;
    color: $light-blue;
    margin-bottom: 0;
  }

  &__location {
    color: $dark-blue;
  }

  &__button {
    display: block;
    max-width: 162px;
    margin: $base-space-unit auto 64px auto;
    cursor: pointer;
  }
}

