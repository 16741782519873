.page-title {

  .container {
    max-width: 1200px;
  }

  &__header {
    font-size: 5em;
    @include grid-column(5);
    text-align: right;
    font-family: $playfair;
    color: $purple;
    margin-bottom: 0;

    span {
      font-size: 0.75em;
      display: block;
      color: lighten($purple, 10%);
    }
  }

  &__subtext {
    @include grid-column(7);
    margin-top: 12px;
    padding-left: 30px;

    p:last-of-type {
      margin-bottom: 0;
    }

    a {
      color: $light-blue;
    }
  }


  @media (max-width: 1300px) {
    &__header {
      @include grid-column(6);
    }

    &__subtext {
      @include grid-column(6);
    }
  }

  @media (max-width: 990px) {
    &__header {
      @include grid-column(12);
      text-align: left;
    }

    &__subtext {
      @include grid-column(12);
      padding: 0;
    }
  }
}