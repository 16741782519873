.checkout {

  button {
    margin-top: 15px;
  }

  &__heading {
    @extend .basket__heading;
  }

  &__container {
    @extend .basket__container;
  }

  &__stripe-element {
    @extend .input;
    background: $white;
    padding-top: 11px;
    padding-bottom: 11px;

    &.attention {
      border: 1px solid crimson;
    }

    &.expiry {
      @include grid-column(3);
    }

    &.cvc {
      @include grid-column(2);
      width: 81px !important;
    }



    $bg-pos: 98% center;
    &.number {
      background-position: 150% center;
      background-repeat: no-repeat;
      background-size: 51px 32px;
      @include transition(background-position 1s ease-out);
      background-image: url('../images/cards/unknown.png');

      &[data-card_type=visa] {
        background-image: url('../images/cards/visa.png');
        background-position: $bg-pos;
      }

      &[data-card_type=mastercard] {
        background-image: url('../images/cards/mastercard.png');
        background-position: $bg-pos;
      }

      &[data-card_type=amex] {
        background-image: url('../images/cards/amex.png');
        background-position: $bg-pos;
      }

      &[data-card_type=maestro] {
        background-image: url('../images/cards/maestro.png');
        background-position: $bg-pos;
      }

      &[data-card_type=switch] {
        background-image: url('../images/cards/visa.png');
        background-position: $bg-pos;
      }

      &[data-card_type=solo] {
        background-image: url('../images/cards/visa.png');
        background-position: $bg-pos;
      }

      &[data-card_type=eurocard] {
        background-image: url('../images/cards/unknown.png');
        background-position: $bg-pos;
      }

      &[data-card_type=visa_electron] {
        background-image: url('../images/cards/visa_electron.png');
        background-position: $bg-pos;
      }

      &[data-card_type=jcb] {
        background-image: url('../images/cards/jcb.png');
        background-position: $bg-pos;
      }

      &[data-card_type=discover] {
        background-image: url('../images/cards/discover.png');
        background-position: $bg-pos;
      }

      &[data-card_type=diners] {
        background-image: url('../images/cards/diners.png');
        background-position: $bg-pos;
      }

      &[data-card_type=unionpay] {
        background-image: url('../images/cards/unionpay.png');
        background-position: $bg-pos;
      }
    }
  }
}