.contact-icon {
	background:transparent url('../images/contact-icon.svg') center no-repeat;
	background-size: contain;
}

.email-icon {
	background:transparent url('../images/email-icon.svg') center no-repeat;
	background-size: contain;
}

.phone-icon {
	background:transparent url('../images/phone-icon.svg') center no-repeat;
	background-size: contain;
}

.menu-close {
	background:transparent url('../images/menu-close.svg') center no-repeat;
	background-size: contain;
}

.menu-toggle {
	background:transparent url('../images/menu-toggle.svg') center no-repeat;
	background-size: contain;
}

.full-logo {
	background:transparent url('../images/full-logo.svg') center no-repeat;
	background-size: contain;
}

.small-logo {
	background:transparent url('../images/small-logo.svg') center no-repeat;
	background-size: contain;
}