.course-section {
  padding-top: $base-space-unit;
  padding-bottom: $base-space-unit;

  .container {
    max-width: 990px;
  }

  .section-title {
    color: $light-blue;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.course-card {
  color: $white;
  padding: 4em 3.5em;
  text-decoration: none;
  cursor: pointer;
  width: 100%;

  &.residential {
    background-color: $light-blue;

    &:hover {
      background-color: darken($light-blue, 2%);
    }
  }

  &.local {
    background-color: $green;

    &:hover {
      background-color: darken($green, 2%);
    }
  }

  &.start {
    background-color: $red;

    &:hover {
      background-color: darken($red, 2%);
    }
  }

  &.moving {
    background-color: $dark-blue;

    &:hover {
      background-color: darken($dark-blue, 2%);
    }
  }
}

.course-card__title {
  font-size: $font-size-7 * 0.65;
  font-family: $playfair;
  margin-bottom: 41px;
}

.course-card__text {
  margin-bottom: 0;
  max-width: 280px;
  height: 40px;

  span {
    text-decoration: underline;
  }
}

@media (min-width: $tablet-m) {
  .course-card {
    width: 50%;
  }
}

@media (min-width: $desktop-l) {
  .course-card__title {
    font-size: $font-size-7;
  }
}