.snippet-special-offer {
  background-color: #dee4ee;
  padding: 20px 30px;
  margin-bottom: 10px;
  margin-top: 10px;

  h4 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }
}