.product-categories {
  @extend .resource-categories;
  margin-bottom: 60px;

  &__container {
    @extend .resource-categories__container;
  }

  &__category {
    @extend .resource-categories__category;

    &--selected{
      text-decoration: underline;
      color: $purple;
    }
  }
}