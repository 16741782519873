.blog-post {
  padding-top: $base-space-unit;
  padding-bottom: $base-space-unit;
}

.blog-post__content {
  img {
    max-width: 100%;
    height: auto !important;
  }
}

.blog-post__title {
  font-family: $playfair;
  color: $green;
  font-size: $font-size-8;
  margin-bottom: 0;
}

.blog-post__date {
  font-size: $font-size-4;
  color: $dark-blue;
}

.blog-post__image {
  height: 440px;
  background: $green url('image.png') center no-repeat;
  background-size: cover;
  margin-bottom: $base-space-unit;
}

@media (min-width: $tablet-m) {

  .blog-post .row {
    @include grid-shift(1);
    @include grid-column(10);
  }
}