table {
	border-collapse: collapse;
	width: 100%;
}

th {
	width: 25%;
}


th,td {
	text-align: center;
	border: none;
	padding: 0.6em 1em;
}

tbody tr {
	&:nth-child(odd){
		//td { background: $tertiary-colour; }
	}
	&:nth-child(even){}


	td {
		//background: $secondary-colour;
		&:first-child {}
	}
}




tfoot tr {
	&:nth-child(odd) {}
	&:nth-child(even) {}

	td {
		background: $white;
		&:first-child {
		}
	}
}