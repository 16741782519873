.announcement {
  padding-top: 0 !important;

  &__container {
    @extend .container;
    background: $purple;
    border: 4px solid darken($purple, 10%);
    color: $white;
    padding: 40px;

    h2 {
      font-size: 2em;
      margin: 0 0 10px 0;
    }

    a {
      color: $white;
    }
  }
}