// UTILITY CLASSES	

// Every utility class should be created with the understanding of a few restrictions
// - Every class name should start with .u- so that anyone looking at the html will be
//   able to tell what file the class is created in, and that -u stands for utility

// - They should be treated as making something immmutable. If all the text in a div is 
//   aligned to the center, AND it will always remain that way, then you can use a utility class

// - The name should be very descriptive, or contain a commonly known shorthand for what it does

// - !important should be appended onto the end, to re-inforce how they should be used, ie only when something 
//   will never change on a component.

.u-cf {
	@extend %clearfix;
}

.u-text-left { text-align: left !important; }
.u-text-right { text-align: right !important; }
.u-text-center { text-align: center !important; }
.u-text-justify { text-align: justify !important; }

.u-lowercase { text-transform: lowercase !important; }
.u-uppercase { text-transform: uppercase !important; }
.u-capitalise { text-transform: capitalize !important; }

.u-full-image {
	display: block;
	width: 100%;
	height: auto;
}