.roadmap-growth {
  padding: 0 !important;

  &__text {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 6em 1.5em !important;

    @media (max-width: 1100px) {
      width: 100%;
    }

    h3 {
      font-family: $playfair;
      color: $eie-green;
      text-align: center;
      font-size: 3em;

      @media (max-width: 425px) {
        font-size: 2.2em;
      }
    }

    p {
      text-align: center;
      font-size: 1.2em;
      margin: 40px 0;
    }

    ol {
      padding: 0 40px;
      list-style: none;
      counter-reset: li;

    }

    li {
      font-family: $playfair;
      color: $eie-grey;
      font-size: 2em;
      line-height: 1.3;
      counter-increment: li;

      @media (max-width: 425px) {
        font-size: 1.5em;
      }

      &::before {
        content: counter(li);
        color: $eie-darkblue;
        display: inline-block;
        width: 1em;
        margin-left: -1em
      }

    }

    &-container {
      max-width: 60%;
      float: right;
      vertical-align: middle;
      padding: 0 40px;

      @media (max-width: 1700px){
        max-width: 75%;
      }

      @media (max-width: 1500px){
        max-width: 85%;
      }

      @media (max-width: 1350px){
        max-width: 100%;
      }

      @media (max-width: 1100px){
        max-width: 80%;
        margin: auto;
        float: none;
      }

      @media (max-width: 650px) {
        max-width: 100%;
      }

      @media (max-width: 425px) {
        padding: 0;
      }
    }
  }

  &__image {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 0 20px;

    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: url("../../../data/cms_images/feet.png");


    @media (max-width: 1100px) {
      width: 100%;
    }
  }

  &__quote {
    min-width: 60%;
    max-width: 60%;
    font-family: $header-font;
    font-size: 2.5em;
    float: left;
    padding: 6em 50px !important;
    vertical-align: middle;
    position: relative;
    top: 9%;
    color: $white;
    line-height: 1.2;


    @media (max-width: 1700px){
      min-width: 75%;
      max-width: 75%;
    }

    @media (max-width: 1500px){
      min-width: 85%;
      max-width: 85%;
    }

    @media (max-width: 1350px){
      min-width: 100%;
      max-width: 100%;
    }

    @media (max-width: 1100px){
      max-width: 80%;
      min-width: 80%;
      margin: auto;
      float: none;
    }

    @media (max-width: 650px){
      max-width: 100%;
      min-width: 100%;
    }

    @media (max-width: 425px){
      font-size: 2.2em;
      top:0;
    }

    p {
      margin-bottom: 40px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: $body-colour;
    opacity: 0.5;
  }
}