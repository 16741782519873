* {
  @include box-sizing();
}

html {
  font-size: $em-base;
  @include box-sizing();
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  line-height: $line-height;
  font-family: $body-font;
  color: $body-colour;
  background: $white;
  min-height: 100%;
  font-weight: 300;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: $base-space-unit 0 0 0;
}

img {
  max-width: 100%;
  height: auto;
}

footer {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

section {
  padding: 40px 0;
}

ul {
  padding-left: 0;
  list-style-position: inside;
}

@media (min-width: $tablet-m) {

  body {
    margin-top: 160px;
  }
}