.resource-categories {
  background: darken($faded-blue, 10%);
  padding: 10px !important;

  &__container {
    @extend .container;
    text-align: center;
  }

  &__category {
    display: inline-block;
    margin: 0 20px;
    cursor: pointer;
    color: darken($faded-blue, 50%);
    font-size: 1.5em;
    font-family: $header-font;
    text-decoration: none;

    &:hover {
      color: $purple;
    }
  }
}