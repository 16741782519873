.apprise-overlay {
	background-color: #000;
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 500;
}

div.apprise {
	color: white;
	display: none;
	font-size: 14px;
	left: 40%;
	max-height: 90%;
	overflow: hidden;
	position: fixed;
	top: -100%;
	width: 20%;
	z-index: 505;
}

div.apprise .apprise-inner {
	padding: 20px;
}

div.apprise .apprise-input {
	margin-top: 10px;
	padding: 10px 0;
}

div.apprise .apprise-input input {
	border: 1px solid rgba(0,0,0,0.3);
	border-radius: 2px;
	-moz-border-radius: 2px;
	-webkit-border-radius: 2px;
	box-shadow: inset 0px 0px 5px rgba(0,0,0,0.1);
	-mox-box-shadow: inset 0px 0px 5px rgba(0,0,0,0.1);
	-webkit-box-shadow: inset 0px 0px 5px rgba(0,0,0,0.1);
	display: block;
	font-size: 13px;
	margin: 0 auto;
	padding: 5px 10px;
	width: 90%;
}

div.apprise .apprise-input input:focus {
	border-color: #01AEF0;
	outline: none;
}

div.apprise .apprise-buttons {
	text-align: center;
	background-color: white;
}

div.apprise .apprise-buttons button {
	cursor: pointer;
	font-size: 20px;
	margin: 0 2px;
	overflow: hidden;
	padding: 13px 26px;
	border: none !important;
	position: relative;
	top: -20px;
}

div.apprise .apprise-buttons button.blue {
	background: #01AEF0;
}

div.apprise .apprise-buttons button.red {
	background: #D23A30;
}

div.apprise .apprise-buttons button.blue, div.apprise .apprise-buttons button.red {
	color: #fff;
	text-shadow: 0px -1px 0px rgba(0,0,0,0.4);
}

div.apprise .apprise-buttons button:hover {
	box-shadow: inset 0px 1px 0px rgba(255,255,255,0.5), 0px 1px 3px rgba(0,0,0,0.4);
	-moz-box-shadow: inset 0px 1px 0px rgba(255,255,255,0.5), 0px 1px 3px rgba(0,0,0,0.4);
	-webkit-box-shadow: inset 0px 1px 0px rgba(255,255,255,0.5), 0px 1px 3px rgba(0,0,0,0.4);
}

div.apprise .apprise-buttons button:active {
	box-shadow: inset 0px 1px 2px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,0.8);
	-moz-box-shadow: inset 0px 1px 2px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,0.8);
	-webkit-box-shadow: inset 0px 1px 2px rgba(0,0,0,0.5), 0px 1px 0px rgba(255,255,255,0.8);
}