.full-width-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 800px;
  width: 100%;

  @media (max-width: 1400px) {
    height: 600px;
  }
}