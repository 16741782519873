.three-col-section {
  padding-top: $base-space-unit;
  padding-bottom: $base-space-unit;

  li {
    margin-bottom: 0px;
    margin-left: 1em
  }

  .section-title {
    color: $light-blue;
  }
}

.three-col-section .row {
/*  columns: 2 300px;
  column-gap: 2em;
  column-fill: initial;*/

  // I HATE COLUMNS
}

@media (min-width: $tablet-m) {
  .three-col-section .row {
    @include grid-shift(1);
    @include grid-column(10);
  }
}

.three-col-section .row {
  @media (min-width: $tablet-l) {
    //max-height: 300px; // this might need to change.
    columns: 3 300px;
    column-gap: 2em;
  }
}