.button {
  display: inline-block;
  background-color: $dark-blue;
  padding: 0.1em .6em;
  min-width: 162px;
  text-align: center;
  color: $white;
  font-family: $header-font;
  color: $white;
  text-decoration: none;
  font-size: 20px;

  &:hover {
    background-color: darken($dark-blue, 8%);
  }
}