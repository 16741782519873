.quote-slider .section-title {
  color: $dark-blue;
}

.quote-slider {
  padding-top: $base-space-unit;
  padding-bottom: $base-space-unit;
}

.quote-slider__pager {
  text-align: center;

  span {
    display: inline-block;
    height: 31px;
    width: 31px;
    border: 3px solid $dark-blue;
    margin: 30px 10px 0;
    font-size: 0;
    cursor: pointer;
    transition: background-color .1s ease-in-out;

    &.cycle-pager-active {
      background-color: $dark-blue;
    }
  }
}

.quote-slider__slide {
  border: 4px solid $dark-blue;
  background: $dark-blue;
  color: $white;
  padding: 64px;
  width: 100%;
  height: auto;
}

.quote {
  font-family: $merriweather;
  font-size: $font-size-6;
  margin-bottom: 54px;
}

.quote__name {
  font-style: normal;
  font-size: $font-size-4;
}

@media(max-width: 990px) {
  .quote {
    font-size: 2em !important;
  }
}

@media (min-width: $tablet-m) {
  .quote-slider__container {
    max-width: 990px;
    margin: 0 auto;
  }
}