.images-section {
	text-align: center;
	padding-top: $base-space-unit;
	padding-bottom: $base-space-unit;
	// background-color: $white;


	.images-section__box {
		display: inline-block;
		text-align: center;
		width: 23%;
		margin-right: 2%;
		vertical-align: middle;

		&:first-of-type {
			text-align: left !important;
		}

		&:last-of-type {
			text-align: right;
			margin-right: 0;
		}
	}


	img {
		display: inline-block;
		// width: 100%;
	}
}


@media (min-width: $tablet-m) {
	.images-section .row {
		@include grid-shift(1);
		@include grid-column(10);
	}
}