// Define all global grid objects here
.container {
  width: 100%;
  max-width: 1360px;
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
  height: auto;

  &--header {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
  }
}