.product-section {
  display: none; /*Temporarily hidden*/

  padding-top: $base-space-unit;
  padding-bottom: $base-space-unit;
}

.product-section__image {
  margin-bottom: 1.5em;

  img {
    width: 100%;
  }
}

.product-section__text {
  h2 {
    color: $purple;
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1.5em;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: $mobile-space-unit;
    color: $body-colour;

    li {
      padding-left: 1em;
      text-indent: -.7em;
      margin-bottom: 0.2em;
    }

    li::before {
      content: "● ";
      color: $green;
      padding-right: 1em;
    }
  }
}

.product-section__price {
  color: $dark-blue;
  font-family: $header-font;
  font-size: $font-size-3;
  margin-bottom: 20px;
}

@media (min-width: $tablet-m) {
  .product-section__image {
    @include grid-shift(1);
    @include grid-column(3.5);
  }

  .product-section__text {
    @include grid-shift(0.5);
    @include grid-column(6);
  }
}