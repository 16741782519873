.shop-outer {
  padding-top: 0 !important;

  &__container {
    @extend .container;
  }

  &__product {
    @include grid-column(3);
    text-align: center;
    margin-bottom: 40px;
    @include transition(all 0.5s ease);

    &:hover {
      @include transform(scale(0.98));
    }

    &:nth-child(4n+4) {
      margin-right: 0;
    }

    @media (max-width: 900px) {
      @include grid-column(6);

      &:nth-child(2n+2) {
        margin-right: 0;
      }
    }

    @media (max-width: 500px) {
      @include grid-column(12);
      margin-right: 0;
    }

    &:hover {
      &, a {
        color: $purple;
      }
    }

    &-title {
      font-size: 1.5em;
      margin: 10px auto;
      overflow: hidden;
      height: 54px;

      a {
        text-decoration: none;
      }
    }

    &-image {
      @include transition(all 0.5s ease);
      width: 100%;
      height: 267px;
      min-height: 100px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
.shop-title{
  @extend .page-title__header;
  @include grid-column(12);
  text-align: center;
}
