.product-slide {

  &__container {
    @extend .container;
  }

  &__image-section {
    @include grid-column(6);
  }

  &__image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__text-content {
    @include grid-column(6);
    padding: 5% 40px;

    h4{
      font-size: 3rem;
      color: #bf5c8a;
      font-family: "Playfair Display",serif;
    }

    .product-slide__price {
      padding-bottom: 5%;

      strong {
        font-size: 1.5rem;
        font-family: "Playfair Display", serif;
      }
    }
  }

  @media (max-width: 1100px){
    &__text-content{
      padding-top:2%;
    }
  }
  
  @media (max-width:1000px) {
    &__image-section{
      @include grid-column(6);
    }

    &__text-content{
      @include grid-column(6);
      padding-top: 0;

      p{
        padding-bottom:0px;
      }
    }
  }

  @media (max-width:770px) {
    &__image-section {
      @include grid-column(12);
    }

    &__text-content {
      @include grid-column(12);
      padding: 20px 0;

      p{
        margin-bottom: 10px;
      }

      h4{
        margin-bottom: 10px;
      }
    }
  }
}

