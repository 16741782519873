@charset "UTF-8";
/* This stylesheet generated by Transfonter (https://transfonter.org) on May 12, 2017 8:46 AM */
@font-face {
  font-family: "Woodford Bourne";
  src: url("../fonts/WoodfordBourne-Regular.eot");
  src: url("../fonts/WoodfordBourne-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/WoodfordBourne-Regular.woff") format("woff"), url("../fonts/WoodfordBourne-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* ==========================================================================
Colours
========================================================================== */
.basket__product:after, .basket__product-header:after, .shop-inner__info-button:after, .site-footer .info-row:after, .resource-box__resource:after, .event-box__event:after, .u-cf:after {
  content: "";
  display: table;
  clear: both;
}

.apprise-overlay {
  background-color: #000;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
}

div.apprise {
  color: white;
  display: none;
  font-size: 14px;
  left: 40%;
  max-height: 90%;
  overflow: hidden;
  position: fixed;
  top: -100%;
  width: 20%;
  z-index: 505;
}

div.apprise .apprise-inner {
  padding: 20px;
}

div.apprise .apprise-input {
  margin-top: 10px;
  padding: 10px 0;
}

div.apprise .apprise-input input {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
  -mox-box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: block;
  font-size: 13px;
  margin: 0 auto;
  padding: 5px 10px;
  width: 90%;
}

div.apprise .apprise-input input:focus {
  border-color: #01AEF0;
  outline: none;
}

div.apprise .apprise-buttons {
  text-align: center;
  background-color: white;
}

div.apprise .apprise-buttons button {
  cursor: pointer;
  font-size: 20px;
  margin: 0 2px;
  overflow: hidden;
  padding: 13px 26px;
  border: none !important;
  position: relative;
  top: -20px;
}

div.apprise .apprise-buttons button.blue {
  background: #01AEF0;
}

div.apprise .apprise-buttons button.red {
  background: #D23A30;
}

div.apprise .apprise-buttons button.blue, div.apprise .apprise-buttons button.red {
  color: #fff;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

div.apprise .apprise-buttons button:hover {
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5), 0px 1px 3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5), 0px 1px 3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.5), 0px 1px 3px rgba(0, 0, 0, 0.4);
}

div.apprise .apprise-buttons button:active {
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.8);
  -moz-box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.8);
  -webkit-box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.5), 0px 1px 0px rgba(255, 255, 255, 0.8);
}

.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-justify {
  text-align: justify !important;
}

.u-lowercase {
  text-transform: lowercase !important;
}

.u-uppercase {
  text-transform: uppercase !important;
}

.u-capitalise {
  text-transform: capitalize !important;
}

.u-full-image {
  display: block;
  width: 100%;
  height: auto;
}

body > p {
  display: none;
}

.apprise {
  background: #fff;
  padding: 32px;
  color: #516FA3 !important;
}
.apprise-buttons {
  text-align: left !important;
}
.apprise-buttons button {
  top: 0 !important;
}
.apprise-inner {
  padding: 0 !important;
}

.apprise h2 {
  font-size: 3em;
  margin-bottom: 20px;
}

.wysiwyg-editor {
  padding: 20px !important;
  margin: 0 !important;
}
.wysiwyg-editor > p {
  display: block !important;
}

.working {
  opacity: 0.5;
  cursor: not-allowed;
}

.hidden {
  display: none;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

legend {
  color: #516FA3;
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 1.5em;
  margin: 0;
}

fieldset label {
  position: relative;
}

fieldset label span {
  position: absolute;
  top: 8px;
  right: 16px;
  color: #516FA3;
}

.checkbox input {
  width: 32px;
  display: none;
}

.checkbox input + em {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #516FA3;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  top: 2px;
  margin-right: 8px;
  margin-bottom: 5px;
  float: left;
}

.checkbox input + em:after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-image: url("../images/tick.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  left: 1px;
  bottom: -32px;
  transition: all 0.5s ease;
}

.checkbox input:checked + em:after {
  bottom: 0px;
}

.info-link {
  position: static;
  cursor: pointer;
  text-decoration: underline;
  margin: 0 0 0 0.25em;
}
.info-link:hover {
  color: #496392;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 15px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  line-height: 1.92;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  background: #fff;
  min-height: 100%;
  font-weight: 300;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 2.67rem 0 0 0;
}

img {
  max-width: 100%;
  height: auto;
}

footer {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

section {
  padding: 40px 0;
}

ul {
  padding-left: 0;
  list-style-position: inside;
}

@media (min-width: 1000px) {
  body {
    margin-top: 160px;
  }
}
/* ==========================================================================
Form, Label and Legend & Inputs
========================================================================== */
/* All rules within this section are opinionated. They can be removed or altered
without cross-browser rendering being affected */
form {
  margin-bottom: 0;
  font-family: sans-serif;
}

label {
  display: block;
  margin-bottom: 0.267rem;
}

legend {
  display: block;
  margin-bottom: 2.67rem;
}

/* ==========================================================================
Buttons and Button-Like Inputs
========================================================================== */
/**
 * 1. Background needs to be defined so that is consistent across browsers
 *
 * 2. Border definition added to prevent different styling across browsers,
 *    also, it's a good idea to be consistent with whether buttons/inputs
 *    are going to have borders otherwise they will become difficult to size
 *    equally.
 *
 * 3. Radius added to prevent default styling by Safari on iOS.
 *
 * 4. Make alignment consistent
 *
 * 5. Stop browsers styling buttons by defauly with that horrible gradient!
 *
 */
button,
input[type=button],
input[type=reset],
input[type=submit] {
  background: #7BB142; /* 1 */
  border: 1px solid #7BB142; /* 2 */
  border-radius: 0; /* 3 */
  text-align: center; /* 4 */
  -webkit-appearance: none; /* 5 */
  -moz-appearance: none; /* 5 */
  appearance: none; /* 5 */
  color: #fff;
  white-space: nowrap;
  font-family: "Woodford Bourne", sans-serif;
  font-size: 20px;
  margin-bottom: 0;
  padding: 6px 6px;
  cursor: pointer;
  display: inline-block;
  min-width: 162px;
  transition: all 0.2s ease-in-out;
}
button:hover, button:focus,
input[type=button]:hover,
input[type=button]:focus,
input[type=reset]:hover,
input[type=reset]:focus,
input[type=submit]:hover,
input[type=submit]:focus {
  outline: none;
}

/* ==========================================================================
Inputs
========================================================================== */
/**
 * 1. Background needs to be defined so that is consistent across browsers
 *
 * 2. Border definition added to prevent different styling across browsers,
 *    also, it's a good idea to be consistent with whether buttons/inputs
 *    are going to have borders otherwise they will become difficult to size
 *    equally.
 *
 * 3. Radius added to prevent default styling by Safari on iOS.
 *
 * 4. Make alignment consistent
 *
 * 5. Stop browsers styling buttons by defauly with that horrible gradient!
 *
 * 6. Keeps textarea responsive even when cols attribute set.
 *
 */
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
textarea,
select,
.input,
.checkout__stripe-element {
  border: 1px solid #00AAE2; /* 2 */
  border-radius: 0; /* 3 */
  text-align: left; /* 4 */
  font-size: 18px;
  padding: 0.3em 0.8em;
  font-family: "Open Sans", sans-serif;
  box-shadow: none; /* 5 */
  width: 100%; /* 6 */
  display: block;
  color: #00AAE2;
  margin-bottom: 1.6em;
}
input[type=email]:hover, input[type=email]:focus,
input[type=number]:hover,
input[type=number]:focus,
input[type=password]:hover,
input[type=password]:focus,
input[type=search]:hover,
input[type=search]:focus,
input[type=tel]:hover,
input[type=tel]:focus,
input[type=text]:hover,
input[type=text]:focus,
input[type=url]:hover,
input[type=url]:focus,
textarea:hover,
textarea:focus,
select:hover,
select:focus,
.input:hover,
.checkout__stripe-element:hover,
.input:focus,
.checkout__stripe-element:focus {
  outline: none;
}

textarea {
  height: 10em;
}

input[type=checkbox] {
  width: 1.2em;
  height: 1.2em;
}

/* ==========================================================================
Input Placeholders
========================================================================== */
/**
 * 1. Set placeholder text to same opacity across browsers.
 */
::-webkit-input-placeholder {
  color: #00AAE2;
  opacity: 1; /* 1 */
}

:-moz-placeholder { /* Firefox 18- */
  color: #00AAE2;
  opacity: 1; /* 1 */
}

::-moz-placeholder { /* Firefox 19+ */
  color: #00AAE2;
  opacity: 1; /* 1 */
}

:-ms-input-placeholder {
  color: #00AAE2;
  opacity: 1; /* 1 */
}

/* ==========================================================================
Headings
========================================================================== */
/* 
* The CSS below for the headings really looks like it should be abstracted out to 
* something more dry but the idea here is that it provides a super easy place to make
* any small tweaks that the specific project might require.
*
* The extra h7 class is just for common areas where text is small eg copyright in footer,
* it's smaller than the body text.
* 
* The .h1 - .h7 classes only imitate the sizing on h1 - h6 elements, not the font properties.
*/
h1, h2, h3,
h4, h5, h6 {
  font-family: "Woodford Bourne", sans-serif;
  font-weight: normal;
}

h1 {
  font-size: 4.221em;
  line-height: 1.2;
  margin: 0 0 2.2695rem;
}

h2 {
  font-size: 4.422em;
  line-height: 1.2;
  margin: 0 0 2.67rem;
}

h3 {
  margin: 0 0 16px 0;
  font-family: "Woodford Bourne";
  font-size: 2em;
  line-height: 1.5em;
  color: #00AAE2;
}

h4 {
  font-size: 1.34em;
  line-height: 1.3;
  margin: 0 0 2.67rem;
}

h5 {
  font-size: 1.206em;
  line-height: 1.35;
  margin: 0 0 2.67rem;
}

h6 {
  font-size: 1.072em;
  line-height: 1.4;
  margin: 0 0 2.67rem;
}

p, hr {
  margin: 0 0 1.92em;
}

a {
  display: inline-block;
  color: #000;
  text-decoration: underline;
}

blockquote {
  margin: 0 0 2.67rem;
  border-left: 5px solid #BF5C8A;
  padding: 0.7em 0 0.7em 1.5em;
  font-style: italic;
}
blockquote p:last-of-type {
  margin-bottom: 0.4em;
}
blockquote cite {
  display: block;
  font-style: normal;
}

pre {
  margin: 0 0 2.67rem;
  background: #D8E6E7;
  padding: 2.67rem;
  color: #6E7783;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  height: 300px;
  overflow-y: scroll;
}

mark {
  background: yellow;
  color: inherit;
}

code {
  color: inherit;
}

a, a:visited, a:active {
  outline: none !important;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  width: 25%;
}

th, td {
  text-align: center;
  border: none;
  padding: 0.6em 1em;
}

tfoot tr td {
  background: #fff;
}
/* ==========================================================================
Lists
========================================================================== */
ul, ol {
  margin-top: 0;
  margin-bottom: 2.67rem;
}
ul li, ol li {
  margin-bottom: 1.335rem;
}
ul li ul, ul li ol, ol li ul, ol li ol {
  margin-top: 1.335rem;
  margin-bottom: 1.335rem;
}

dl {
  margin-top: 0;
  margin-bottom: 2.67rem;
}
dl dt {
  font-weight: bold;
}
dl dd {
  margin-left: 0;
  padding-left: 2.67rem;
  font-style: italic;
  margin-bottom: 2.67rem;
}

.container, .announcement__container, .your-downloads, .basket__container, .checkout__container, .shop-inner__container, .shop-outer__container, .product-slide__container, .resource-categories__container, .product-categories__container, .resource-section__container {
  width: 100%;
  max-width: 1360px;
  overflow: hidden;
  margin: 0 auto;
  padding: 0;
  height: auto;
}
.container--header {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

.button {
  display: inline-block;
  background-color: #516FA3;
  padding: 0.1em 0.6em;
  min-width: 162px;
  text-align: center;
  color: #fff;
  font-family: "Woodford Bourne", sans-serif;
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}
.button:hover {
  background-color: #435c88;
}

.contact-icon {
  background: transparent url("../images/contact-icon.svg") center no-repeat;
  background-size: contain;
}

.email-icon {
  background: transparent url("../images/email-icon.svg") center no-repeat;
  background-size: contain;
}

.phone-icon {
  background: transparent url("../images/phone-icon.svg") center no-repeat;
  background-size: contain;
}

.menu-close {
  background: transparent url("../images/menu-close.svg") center no-repeat;
  background-size: contain;
}

.menu-toggle {
  background: transparent url("../images/menu-toggle.svg") center no-repeat;
  background-size: contain;
}

.full-logo {
  background: transparent url("../images/full-logo.svg") center no-repeat;
  background-size: contain;
}

.small-logo {
  background: transparent url("../images/small-logo.svg") center no-repeat;
  background-size: contain;
}

.course-section {
  padding-top: 2.67rem;
  padding-bottom: 2.67rem;
}
.course-section .container, .course-section .announcement__container, .course-section .your-downloads, .course-section .basket__container, .course-section .checkout__container, .course-section .shop-inner__container, .course-section .shop-outer__container, .course-section .product-slide__container, .course-section .resource-categories__container, .course-section .product-categories__container, .course-section .resource-section__container {
  max-width: 990px;
}
.course-section .section-title {
  color: #00AAE2;
}
.course-section .row, .course-section .basket__product, .course-section .basket__product-header {
  display: flex;
  flex-wrap: wrap;
}

.course-card {
  color: #fff;
  padding: 4em 3.5em;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
}
.course-card.residential {
  background-color: #00AAE2;
}
.course-card.residential:hover {
  background-color: #00a2d8;
}
.course-card.local {
  background-color: #7BB142;
}
.course-card.local:hover {
  background-color: #76aa3f;
}
.course-card.start {
  background-color: #DA494C;
}
.course-card.start:hover {
  background-color: #d84144;
}
.course-card.moving {
  background-color: #516FA3;
}
.course-card.moving:hover {
  background-color: #4e6a9c;
}

.course-card__title {
  font-size: 2.56945em;
  font-family: "Playfair Display", serif;
  margin-bottom: 41px;
}

.course-card__text {
  margin-bottom: 0;
  max-width: 280px;
  height: 40px;
}
.course-card__text span {
  text-decoration: underline;
}

@media (min-width: 1000px) {
  .course-card {
    width: 50%;
  }
}
@media (min-width: 1150px) {
  .course-card__title {
    font-size: 3.953em;
  }
}
.section-title {
  text-align: center;
}

.modal-bg {
  display: none;
  background: #516FA3;
  opacity: 0.86;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  transition: all 0.2s ease-in-out;
}
.modal-bg.is-open {
  display: block;
}

.event-popup {
  background-color: #fff;
  padding: 2.67rem;
  max-width: 830px;
  margin: 0 auto;
  position: fixed;
  top: 2.67rem;
  left: 0;
  right: 0;
  display: none;
  z-index: 2000;
  overflow-y: auto;
}
.event-popup__date {
  font-size: 66px;
  color: #00AAE2;
  margin-bottom: 0;
}
.event-popup__location {
  color: #516FA3;
}
.event-popup__button {
  display: block;
  max-width: 162px;
  margin: 2.67rem auto 64px auto;
  cursor: pointer;
}

.global-header {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 998;
}

.header-nav {
  background: transparent url("../images/leaves.jpg") center no-repeat;
  background-size: cover;
  height: 160px;
  padding: 0 32px;
}
.header-nav:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 97;
  max-height: 160px;
}
.header-nav > div {
  z-index: 100;
  position: relative;
}
.header-nav .row, .header-nav .basket__product, .header-nav .basket__product-header {
  display: flex;
}

.nav-header__icon {
  display: inline-block;
  height: 93px;
  width: 137px;
  margin-top: 30px;
  vertical-align: middle;
}

.nav-header__nav {
  height: 160px;
  float: right;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.nav-header__nav a {
  font-size: 1.25em;
  color: #fff;
  text-decoration: none;
  line-height: 160px;
  transition: all 0.2s ease;
  padding: 0 0.5em;
}
.nav-header__nav a:hover {
  text-decoration: underline;
}
.nav-header__nav a:last-of-type {
  padding-right: 0;
}
@media (min-width: 1300px) {
  .nav-header__nav {
    padding: 0 1em;
  }
}

.course-button {
  transition: all 0.1s ease-in-out;
}
.course-button.is-open {
  background-color: #fff;
  color: #000000;
}

.header-courses {
  background-color: #fff;
  padding: 2.14rem 15px;
  display: none;
  box-shadow: 0px 360px 120px 100px rgba(0, 0, 0, 0.5);
}
.header-courses.is-open {
  display: block;
}

.header-courses__course {
  width: calc(25% - 25px);
  float: left;
  margin-left: 20px;
  margin-left: 0;
  text-decoration: none;
  color: #fff;
  padding: 20px;
  height: auto;
  cursor: pointer;
  transition: all 0.1s ease-out;
}
.header-courses__course-row {
  display: flex;
  gap: 16px;
  align-content: center;
  align-items: stretch;
}
.header-courses__course.residential {
  background-color: #00AAE2;
}
.header-courses__course.local {
  background-color: #7BB142;
}
.header-courses__course.start {
  background-color: #DA494C;
}
.header-courses__course.moving {
  background-color: #516FA3;
}
.header-courses__course.diocean {
  background-color: #BF5C8A;
}
.header-courses__course:hover {
  transform: scale(1.1);
}

.header-courses__title {
  font-family: "Playfair Display", serif;
  font-size: 1.5477em;
  margin-bottom: 20px;
  color: #fff;
}

@media (min-width: 1000px) {
  .global-header {
    display: block;
  }
}
@media (min-width: 1150px) {
  .header-courses__title {
    font-size: 2em;
  }
}
.mobile-header {
  background: transparent url("../images/leaves.jpg") center no-repeat;
  padding: 0 15px;
  height: 5em;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.mobile-header:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.mobile-header .row, .mobile-header .basket__product, .mobile-header .basket__product-header {
  position: relative;
  z-index: 100;
}

.mobile-header__icon {
  display: inline-block;
  height: 33px;
  width: 34px;
  margin-top: 15px;
}

.mobile-header__button {
  height: 32px;
  width: 32px;
  display: inline-block;
  background: transparent url("../images/menu-toggle.svg") center no-repeat;
  background-size: contain;
  float: right;
  margin-top: 15px;
  cursor: pointer;
}

.mobile-header__nav {
  list-style: none;
  margin: 0 -15px 0;
  padding: 2em 15px 2em;
  position: relative;
  z-index: 100;
  display: none;
}
.mobile-header__nav li {
  margin-bottom: 5px;
  color: #fff;
  font-size: 18px;
}
.mobile-header__nav li:last-of-type {
  margin-bottom: 0;
}
.mobile-header__nav li a {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
}
.mobile-header__nav li ul {
  margin: 1em 0 1em 5px;
  list-style: none;
  border-left: 2px solid white;
  padding-left: 1em;
}
.mobile-header__nav li ul li, .mobile-header__nav li ul a {
  font-size: 11px;
}

body.mobile-is-open .mobile-header__button {
  background-image: url("../images/menu-close.svg");
}

@media (min-width: 1000px) {
  .mobile-header {
    display: none;
  }
}
.blog-post {
  padding-top: 2.67rem;
  padding-bottom: 2.67rem;
}

.blog-post__content img {
  max-width: 100%;
  height: auto !important;
}

.blog-post__title {
  font-family: "Playfair Display", serif;
  color: #7BB142;
  font-size: 4.221em;
  margin-bottom: 0;
}

.blog-post__date {
  font-size: 1.541em;
  color: #516FA3;
}

.blog-post__image {
  height: 440px;
  background: #7BB142 url("image.png") center no-repeat;
  background-size: cover;
  margin-bottom: 2.67rem;
}

@media (min-width: 1000px) {
  .blog-post .row, .blog-post .basket__product, .blog-post .basket__product-header {
    left: calc(8.3333333333% - 21.6666666667px + 20px);
    position: relative;
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
.video-slide {
  padding-top: 2.67rem;
  padding-bottom: 2.67rem;
}
.video-slide .section-title {
  color: #DA494C;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 1000px) {
  .video-slide .row, .video-slide .basket__product, .video-slide .basket__product-header {
    left: calc(8.3333333333% - 21.6666666667px + 20px);
    position: relative;
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
.quote-banner {
  height: 626px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
}
.quote-banner .container, .quote-banner .announcement__container, .quote-banner .your-downloads, .quote-banner .basket__container, .quote-banner .checkout__container, .quote-banner .shop-inner__container, .quote-banner .shop-outer__container, .quote-banner .product-slide__container, .quote-banner .resource-categories__container, .quote-banner .product-categories__container, .quote-banner .resource-section__container {
  height: 100%;
  position: relative;
}
@media (max-width: 880px) {
  .quote-banner {
    height: 360px;
  }
}
@media (max-width: 600px) {
  .quote-banner {
    height: 240px;
  }
}

.quote-banner__quote {
  background-color: #f8f9fb;
  box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.1);
  color: #516FA3;
  display: inline-block;
  max-width: 400px;
  padding: 48px;
  position: absolute;
  right: 0;
  bottom: 2.67rem;
}
@media (max-width: 990px) {
  .quote-banner__quote {
    display: none;
  }
}
.quote-banner__quote p {
  font-family: "Merriweather", serif;
  font-size: 2.211em;
  margin-bottom: 15px;
  line-height: 1.5;
}
.quote-banner__quote cite {
  font-size: 1.541em;
  font-style: normal;
}

.images-section {
  text-align: center;
  padding-top: 2.67rem;
  padding-bottom: 2.67rem;
}
.images-section .images-section__box {
  display: inline-block;
  text-align: center;
  width: 23%;
  margin-right: 2%;
  vertical-align: middle;
}
.images-section .images-section__box:first-of-type {
  text-align: left !important;
}
.images-section .images-section__box:last-of-type {
  text-align: right;
  margin-right: 0;
}
.images-section img {
  display: inline-block;
}

@media (min-width: 1000px) {
  .images-section .row, .images-section .basket__product, .images-section .basket__product-header {
    left: calc(8.3333333333% - 21.6666666667px + 20px);
    position: relative;
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
.contact-section {
  padding-top: 2.67rem;
  padding-bottom: 5.34rem;
  background-color: #C7ECF9;
}

.contact-section__links {
  text-align: center;
}
.contact-section__links a, .contact-section__links span {
  display: block;
  margin-bottom: 40px;
  text-decoration: none;
  color: #00AAE2;
  font-family: "Woodford Bourne", sans-serif;
  font-size: 20px;
}
.contact-section__links i {
  display: inline-block;
  height: 25px;
  width: 30px;
  vertical-align: middle;
  margin-right: 1.4em;
}

.contact-section__form {
  max-width: 555px;
  margin: 64px auto 0;
}
.contact-section__form .form-error {
  background-color: #ffefef;
}

.event-section {
  padding-top: 2.67rem;
  padding-bottom: 2.67rem;
}
.event-section .section-title {
  color: #516FA3;
}

.event-box {
  border: 3px solid #516FA3;
  padding: 40px;
}

.event-box__event {
  margin-bottom: 2.14rem;
}
.event-box__event:last-of-type {
  margin-bottom: 0;
}

.event-box__date,
.event-box__name,
.event-box__link {
  display: block;
}

.event-box__date {
  font-family: "Woodford Bourne", sans-serif;
  font-size: 20px;
  color: #516FA3;
}

.event-box__name {
  margin-bottom: 10px;
}

@media (min-width: 1000px) {
  .event-box {
    left: calc(8.3333333333% - 21.6666666667px + 20px);
    position: relative;
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
  .event-box__date {
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
  }
  .event-box__name {
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
  }
  .event-box__link {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
    text-align: right;
  }
}
.resource-section {
  background-color: #DEE4EE;
}
.resource-section__header {
  text-align: left;
  color: #516FA3;
  left: calc(8.3333333333% - 21.6666666667px + 20px);
  position: relative;
  width: calc(83.3333333333% - 36.6666666667px);
  float: left;
  margin-left: 20px;
}
.resource-section__header h2 {
  font-size: 3em;
  margin: 0 0 10px 0;
}
@media (max-width: 1000px) {
  .resource-section__header {
    left: calc(0% - 20px + 20px);
    position: relative;
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    padding: 20px;
  }
}
.resource-section__resources {
  margin-bottom: 40px;
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
}
.resource-section .section-title {
  color: #516FA3;
}

.resource-box {
  padding: 0;
  background: #eff2f7;
}

.resource-box__resource {
  margin: 0;
  padding: 20px;
}
.resource-box__resource:nth-child(even) {
  background: #e6ebf2;
}
.resource-box__resource:last-of-type {
  margin-bottom: 0;
}

.resource-box__date,
.resource-box__name,
.resource-box__link {
  display: block;
}

.resource-box__date a {
  font-family: "Woodford Bourne", sans-serif;
  font-size: 1.25em;
  color: #516FA3;
  line-height: 1em;
  text-decoration: none;
}
.resource-box__date a:hover {
  text-decoration: underline;
  color: #BF5C8A;
}

.resource-box__name a {
  color: #BF5C8A;
}

.resource-box__link .button {
  font-size: 1em;
}

@media (min-width: 1000px) {
  .resource-box {
    left: calc(8.3333333333% - 21.6666666667px + 20px);
    position: relative;
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
  .resource-box__date {
    width: calc(41.6666666667% - 28.3333333333px);
    float: left;
    margin-left: 20px;
  }
  .resource-box__name {
    width: calc(33.3333333333% - 26.6666666667px);
    float: left;
    margin-left: 20px;
  }
  .resource-box__link {
    width: calc(25% - 25px);
    float: left;
    margin-left: 20px;
    text-align: right;
  }
}
.resource-categories, .product-categories {
  background: #bcc8dd;
  padding: 10px !important;
}
.resource-categories__container, .product-categories__container {
  text-align: center;
}
.resource-categories__category, .product-categories__category {
  display: inline-block;
  margin: 0 20px;
  cursor: pointer;
  color: #465e87;
  font-size: 1.5em;
  font-family: "Woodford Bourne", sans-serif;
  text-decoration: none;
}
.resource-categories__category:hover, .product-categories__category:hover {
  color: #BF5C8A;
}

.page-title .container, .page-title .announcement__container, .page-title .your-downloads, .page-title .basket__container, .page-title .checkout__container, .page-title .shop-inner__container, .page-title .shop-outer__container, .page-title .product-slide__container, .page-title .resource-section__container, .page-title .resource-categories__container, .page-title .product-categories__container {
  max-width: 1200px;
}
.page-title__header, .shop-title, .your-downloads h1 {
  font-size: 5em;
  width: calc(41.6666666667% - 28.3333333333px);
  float: left;
  margin-left: 20px;
  text-align: right;
  font-family: "Playfair Display", serif;
  color: #BF5C8A;
  margin-bottom: 0;
}
.page-title__header span, .shop-title span, .your-downloads h1 span {
  font-size: 0.75em;
  display: block;
  color: #cd81a4;
}
.page-title__subtext {
  width: calc(58.3333333333% - 31.6666666667px);
  float: left;
  margin-left: 20px;
  margin-top: 12px;
  padding-left: 30px;
}
.page-title__subtext p:last-of-type {
  margin-bottom: 0;
}
.page-title__subtext a {
  color: #00AAE2;
}
@media (max-width: 1300px) {
  .page-title__header, .shop-title, .your-downloads h1 {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
  .page-title__subtext {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
@media (max-width: 990px) {
  .page-title__header, .shop-title, .your-downloads h1 {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    text-align: left;
  }
  .page-title__subtext {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    padding: 0;
  }
}

.page-title-quote {
  background: #6ABF84;
  padding: 0 !important;
  overflow: auto;
}
.page-title-quote__header {
  font-family: "Playfair Display", serif;
  color: #fff;
  font-size: 4.25em;
  text-align: right;
  margin: 0;
}
.page-title-quote__header--inspiring {
  font-size: 5.85em;
}
.page-title-quote__header--equipping {
  font-size: 5.25em;
  margin: 0 0 60px;
}
@media (max-width: 1350px) {
  .page-title-quote__header {
    font-size: 3.5em;
  }
  .page-title-quote__header--inspiring {
    font-size: 4.8em;
  }
  .page-title-quote__header--equipping {
    font-size: 4.3em;
  }
}
@media (max-width: 1140px) {
  .page-title-quote__header {
    font-size: 3em;
  }
  .page-title-quote__header--inspiring {
    font-size: 4.1em;
  }
  .page-title-quote__header--equipping {
    font-size: 3.65em;
  }
}
@media (max-width: 900px) {
  .page-title-quote__header {
    font-size: 2.2em;
  }
  .page-title-quote__header--inspiring {
    font-size: 3em;
  }
  .page-title-quote__header--equipping {
    font-size: 2.7em;
  }
}
@media (max-width: 800px) {
  .page-title-quote__header {
    font-size: 3.5em;
  }
  .page-title-quote__header--inspiring {
    font-size: 4.8em;
  }
  .page-title-quote__header--equipping {
    font-size: 4.3em;
  }
}
@media (max-width: 620px) {
  .page-title-quote__header {
    text-align: left;
  }
}
@media (max-width: 500px) {
  .page-title-quote__header {
    font-size: 2.6em;
  }
  .page-title-quote__header--inspiring {
    font-size: 3.55em;
  }
  .page-title-quote__header--equipping {
    font-size: 3.2em;
  }
}
@media (max-width: 425px) {
  .page-title-quote__header {
    font-size: 2em;
  }
  .page-title-quote__header--inspiring {
    font-size: 2.75em;
  }
  .page-title-quote__header--equipping {
    font-size: 2.5em;
  }
}
.page-title-quote__header-container {
  background: #7CD69A;
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
  width: 50%;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 120px 160px 120px 0;
  position: relative;
}
@media (max-width: 1140px) {
  .page-title-quote__header-container {
    padding: 120px 120px 120px 0;
  }
}
@media (max-width: 800px) {
  .page-title-quote__header-container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    padding: 120px 200px 120px 0;
  }
}
@media (max-width: 620px) {
  .page-title-quote__header-container {
    padding: 120px 70px 120px;
  }
}
@media (max-width: 500px) {
  .page-title-quote__header-container {
    padding: 80px 60px;
  }
}
.page-title-quote__image {
  height: 120px;
  width: 120px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  border-radius: 50%;
}
@media (max-width: 1350px) {
  .page-title-quote__image {
    height: 80px;
    width: 80px;
  }
}
@media (max-width: 800px) {
  .page-title-quote__image {
    height: 120px;
    width: 120px;
    vertical-align: top;
    display: inline-block;
  }
}
.page-title-quote__subtext-container {
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
  width: 50%;
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 120px 80px;
  position: relative;
}
@media (max-width: 900px) {
  .page-title-quote__subtext-container {
    padding: 115px 40px 100px;
  }
}
@media (max-width: 800px) {
  .page-title-quote__subtext-container {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    padding: 120px 100px;
  }
}
@media (max-width: 620px) {
  .page-title-quote__subtext-container {
    padding: 80px 60px;
  }
}
.page-title-quote__subtitle, .page-title-quote__quoter {
  color: #fff;
  margin: 0;
  font-family: "Woodford Bourne", sans-serif;
  max-width: 55%;
  line-height: 1.5;
}
@media (max-width: 1600px) {
  .page-title-quote__subtitle, .page-title-quote__quoter {
    max-width: 70%;
  }
}
@media (max-width: 1350px) {
  .page-title-quote__subtitle, .page-title-quote__quoter {
    max-width: 100%;
  }
}
.page-title-quote__subtitle:last-of-type, .page-title-quote__quoter:last-of-type {
  margin-bottom: 0;
}
@media (max-width: 800px) {
  .page-title-quote__subtitle, .page-title-quote__quoter {
    width: 70%;
    margin: 0 0 0 30px;
    vertical-align: top;
    display: inline-block;
  }
}
@media (max-width: 720px) {
  .page-title-quote__subtitle, .page-title-quote__quoter {
    width: 100%;
    margin: 0;
    display: block;
  }
}
.page-title-quote__subtitle {
  font-size: 1.4em;
}
@media (max-width: 1140px) {
  .page-title-quote__subtitle {
    font-size: 1.2em;
  }
}
@media (max-width: 1030px) {
  .page-title-quote__subtitle {
    font-size: 1em;
  }
}
@media (max-width: 800px) {
  .page-title-quote__subtitle {
    font-size: 1.5em;
  }
}
.page-title-quote__quoter {
  font-size: 1.2em;
  margin-top: 10px;
}
@media (max-width: 1140px) {
  .page-title-quote__quoter {
    font-size: 1.1em;
  }
}
@media (max-width: 1030px) {
  .page-title-quote__quoter {
    font-size: 1em;
  }
}
@media (max-width: 800px) {
  .page-title-quote__quoter {
    margin-left: 150px;
    font-size: 1.2em;
  }
}
@media (max-width: 720px) {
  .page-title-quote__quoter {
    margin-left: 0;
  }
}
@media (max-width: 620px) {
  .page-title-quote__quoter {
    margin-left: 0;
  }
}
.page-title-quote__underline {
  height: 10px;
  width: 83%;
  background: #ba558d;
  display: block;
  position: absolute;
  left: 0;
  bottom: 110px;
}
@media (max-width: 1350px) {
  .page-title-quote__underline {
    width: 75.5%;
  }
}
@media (max-width: 800px) {
  .page-title-quote__underline {
    width: 74.5%;
  }
}
@media (max-width: 620px) {
  .page-title-quote__underline {
    width: 390px;
  }
}
@media (max-width: 500px) {
  .page-title-quote__underline {
    bottom: 85px;
    width: 60%;
  }
}
@media (max-width: 425px) {
  .page-title-quote__underline {
    bottom: 85px;
  }
}

.about-section {
  background-color: #C7ECF9;
  padding-top: 5.34rem;
  padding-bottom: 5.34rem;
}
.about-section ul {
  margin-top: 16px;
}
.about-section ul li {
  margin-bottom: 0px;
}
.about-section h3 {
  text-align: center;
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 2.5em;
  opacity: 0.5;
}

.about-section__text-box {
  background-color: #fff;
  padding: 96px 64px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.25);
}
.about-section__text-box p:last-of-type, .about-section__text-box ul:last-of-type, .about-section__text-box h1:last-of-type, .about-section__text-box h2:last-of-type, .about-section__text-box h3:last-of-type, .about-section__text-box h4:last-of-type, .about-section__text-box h5:last-of-type {
  margin-bottom: 0;
}

.about-section__profile {
  margin-top: 64px;
}

.about-section__profile-image {
  height: 87px;
  width: 87px;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.7em;
}

.about-section__profile-text {
  display: inline-block;
  vertical-align: middle;
}
.about-section__profile-text p {
  margin-bottom: 0;
}
.about-section__profile-text strong {
  font-weight: 600;
}

@media (min-width: 1000px) {
  .about-section__text-box {
    left: calc(16.6666666667% - 23.3333333333px + 20px);
    position: relative;
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
}
.quote-slider .section-title {
  color: #516FA3;
}

.quote-slider {
  padding-top: 2.67rem;
  padding-bottom: 2.67rem;
}

.quote-slider__pager {
  text-align: center;
}
.quote-slider__pager span {
  display: inline-block;
  height: 31px;
  width: 31px;
  border: 3px solid #516FA3;
  margin: 30px 10px 0;
  font-size: 0;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}
.quote-slider__pager span.cycle-pager-active {
  background-color: #516FA3;
}

.quote-slider__slide {
  border: 4px solid #516FA3;
  background: #516FA3;
  color: #fff;
  padding: 64px;
  width: 100%;
  height: auto;
}

.quote {
  font-family: "Merriweather", serif;
  font-size: 2.211em;
  margin-bottom: 54px;
}

.quote__name {
  font-style: normal;
  font-size: 1.541em;
}

@media (max-width: 990px) {
  .quote {
    font-size: 2em !important;
  }
}
@media (min-width: 1000px) {
  .quote-slider__container {
    max-width: 990px;
    margin: 0 auto;
  }
}
.product-section {
  display: none; /*Temporarily hidden*/
  padding-top: 2.67rem;
  padding-bottom: 2.67rem;
}

.product-section__image {
  margin-bottom: 1.5em;
}
.product-section__image img {
  width: 100%;
}

.product-section__text h2 {
  color: #BF5C8A;
  margin-bottom: 0.5em;
}
.product-section__text p {
  margin-bottom: 1.5em;
}
.product-section__text ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 2.14rem;
  color: #000000;
}
.product-section__text ul li {
  padding-left: 1em;
  text-indent: -0.7em;
  margin-bottom: 0.2em;
}
.product-section__text ul li::before {
  content: "● ";
  color: #7BB142;
  padding-right: 1em;
}

.product-section__price {
  color: #516FA3;
  font-family: "Woodford Bourne", sans-serif;
  font-size: 1.34em;
  margin-bottom: 20px;
}

@media (min-width: 1000px) {
  .product-section__image {
    left: calc(8.3333333333% - 21.6666666667px + 20px);
    position: relative;
    width: calc(29.1666666667% - 25.8333333333px);
    float: left;
    margin-left: 20px;
  }
  .product-section__text {
    left: calc(4.1666666667% - 20.8333333333px + 20px);
    position: relative;
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}
.product-slide__image-section {
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
}
.product-slide__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.product-slide__text-content {
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
  padding: 5% 40px;
}
.product-slide__text-content h4 {
  font-size: 3rem;
  color: #bf5c8a;
  font-family: "Playfair Display", serif;
}
.product-slide__text-content .product-slide__price {
  padding-bottom: 5%;
}
.product-slide__text-content .product-slide__price strong {
  font-size: 1.5rem;
  font-family: "Playfair Display", serif;
}
@media (max-width: 1100px) {
  .product-slide__text-content {
    padding-top: 2%;
  }
}
@media (max-width: 1000px) {
  .product-slide__image-section {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
  .product-slide__text-content {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
    padding-top: 0;
  }
  .product-slide__text-content p {
    padding-bottom: 0px;
  }
}
@media (max-width: 770px) {
  .product-slide__image-section {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
  .product-slide__text-content {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    padding: 20px 0;
  }
  .product-slide__text-content p {
    margin-bottom: 10px;
  }
  .product-slide__text-content h4 {
    margin-bottom: 10px;
  }
}

.three-col-section {
  padding-top: 2.67rem;
  padding-bottom: 2.67rem;
}
.three-col-section li {
  margin-bottom: 0px;
  margin-left: 1em;
}
.three-col-section .section-title {
  color: #00AAE2;
}

.three-col-section .row, .three-col-section .basket__product, .three-col-section .basket__product-header {
  /*  columns: 2 300px;
    column-gap: 2em;
    column-fill: initial;*/
}

@media (min-width: 1000px) {
  .three-col-section .row, .three-col-section .basket__product, .three-col-section .basket__product-header {
    left: calc(8.3333333333% - 21.6666666667px + 20px);
    position: relative;
    width: calc(83.3333333333% - 36.6666666667px);
    float: left;
    margin-left: 20px;
  }
}
@media (min-width: 900px) {
  .three-col-section .row, .three-col-section .basket__product, .three-col-section .basket__product-header {
    columns: 3 300px;
    column-gap: 2em;
  }
}

.blog-list {
  padding-top: 2.67rem;
  padding-bottom: 2.67rem;
  background-color: #DEE4EE;
}

.blog-card {
  display: block;
  margin-bottom: 2.67rem;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  transition: all 0.1s ease-out;
}
.blog-card:hover {
  transform: scale(0.95);
}

.blog-card__image {
  height: 13.5em;
  background-size: cover;
}

.blog-card__text {
  background-color: #fff;
  padding: 2em 1.75em;
}

.blog-card__title {
  font-family: "Playfair Display", serif;
  font-size: 3em;
  color: #7BB142;
  margin-bottom: 0;
  padding-bottom: 32px;
}

.blog-card__date {
  font-size: 1.0787em;
  color: #516FA3;
  text-decoration: none;
}

@media (min-width: 1000px) {
  .blog-card__image {
    height: auto;
    left: 8.5298%;
    position: absolute;
    width: 42.64902%;
    top: 0;
    bottom: 0;
  }
  .blog-card__text {
    padding: 4em 3.5em;
    margin-left: 51.17882%;
    width: 40.29137%;
  }
  .blog-card__title {
    font-size: 3em;
  }
  .blog-card__date {
    font-size: 1.25em;
  }
}
.mail-sign-up {
  background-color: #7BB142;
  opacity: 0.8;
  padding: 50px;
}
.mail-sign-up .container, .mail-sign-up .announcement__container, .mail-sign-up .your-downloads, .mail-sign-up .basket__container, .mail-sign-up .checkout__container, .mail-sign-up .shop-inner__container, .mail-sign-up .shop-outer__container, .mail-sign-up .resource-section__container, .mail-sign-up .resource-categories__container, .mail-sign-up .product-categories__container, .mail-sign-up .product-slide__container {
  max-width: 900px;
}
.mail-sign-up .container .mail-sign-up__content .col-1, .mail-sign-up .announcement__container .mail-sign-up__content .col-1, .mail-sign-up .your-downloads .mail-sign-up__content .col-1, .mail-sign-up .basket__container .mail-sign-up__content .col-1, .mail-sign-up .checkout__container .mail-sign-up__content .col-1, .mail-sign-up .shop-inner__container .mail-sign-up__content .col-1, .mail-sign-up .shop-outer__container .mail-sign-up__content .col-1, .mail-sign-up .resource-section__container .mail-sign-up__content .col-1, .mail-sign-up .resource-categories__container .mail-sign-up__content .col-1, .mail-sign-up .product-categories__container .mail-sign-up__content .col-1, .mail-sign-up .product-slide__container .mail-sign-up__content .col-1 {
  width: calc(33.3333333333% - 26.6666666667px);
  float: left;
  margin-left: 20px;
}
.mail-sign-up .container .mail-sign-up__content .col-1 h4, .mail-sign-up .announcement__container .mail-sign-up__content .col-1 h4, .mail-sign-up .your-downloads .mail-sign-up__content .col-1 h4, .mail-sign-up .basket__container .mail-sign-up__content .col-1 h4, .mail-sign-up .checkout__container .mail-sign-up__content .col-1 h4, .mail-sign-up .shop-inner__container .mail-sign-up__content .col-1 h4, .mail-sign-up .shop-outer__container .mail-sign-up__content .col-1 h4, .mail-sign-up .resource-section__container .mail-sign-up__content .col-1 h4, .mail-sign-up .resource-categories__container .mail-sign-up__content .col-1 h4, .mail-sign-up .product-categories__container .mail-sign-up__content .col-1 h4, .mail-sign-up .product-slide__container .mail-sign-up__content .col-1 h4 {
  font-size: 1.8em;
  font-weight: 700;
  font-style: italic;
  color: #fff;
  margin-bottom: 0;
  font-family: "Playfair Display", serif;
}
.mail-sign-up .container .mail-sign-up__content .col-1 p, .mail-sign-up .announcement__container .mail-sign-up__content .col-1 p, .mail-sign-up .your-downloads .mail-sign-up__content .col-1 p, .mail-sign-up .basket__container .mail-sign-up__content .col-1 p, .mail-sign-up .checkout__container .mail-sign-up__content .col-1 p, .mail-sign-up .shop-inner__container .mail-sign-up__content .col-1 p, .mail-sign-up .shop-outer__container .mail-sign-up__content .col-1 p, .mail-sign-up .resource-section__container .mail-sign-up__content .col-1 p, .mail-sign-up .resource-categories__container .mail-sign-up__content .col-1 p, .mail-sign-up .product-categories__container .mail-sign-up__content .col-1 p, .mail-sign-up .product-slide__container .mail-sign-up__content .col-1 p {
  color: #fff;
  font-size: 1em;
  line-height: 1;
}
.mail-sign-up .container .mail-sign-up__content .col-2, .mail-sign-up .announcement__container .mail-sign-up__content .col-2, .mail-sign-up .your-downloads .mail-sign-up__content .col-2, .mail-sign-up .basket__container .mail-sign-up__content .col-2, .mail-sign-up .checkout__container .mail-sign-up__content .col-2, .mail-sign-up .shop-inner__container .mail-sign-up__content .col-2, .mail-sign-up .shop-outer__container .mail-sign-up__content .col-2, .mail-sign-up .resource-section__container .mail-sign-up__content .col-2, .mail-sign-up .resource-categories__container .mail-sign-up__content .col-2, .mail-sign-up .product-categories__container .mail-sign-up__content .col-2, .mail-sign-up .product-slide__container .mail-sign-up__content .col-2 {
  width: calc(66.6666666667% - 33.3333333333px);
  float: left;
  margin-left: 20px;
}
.mail-sign-up .container .mail-sign-up__content .col-2 .email, .mail-sign-up .announcement__container .mail-sign-up__content .col-2 .email, .mail-sign-up .your-downloads .mail-sign-up__content .col-2 .email, .mail-sign-up .basket__container .mail-sign-up__content .col-2 .email, .mail-sign-up .checkout__container .mail-sign-up__content .col-2 .email, .mail-sign-up .shop-inner__container .mail-sign-up__content .col-2 .email, .mail-sign-up .shop-outer__container .mail-sign-up__content .col-2 .email, .mail-sign-up .resource-section__container .mail-sign-up__content .col-2 .email, .mail-sign-up .resource-categories__container .mail-sign-up__content .col-2 .email, .mail-sign-up .product-categories__container .mail-sign-up__content .col-2 .email, .mail-sign-up .product-slide__container .mail-sign-up__content .col-2 .email {
  width: 350px;
  display: inline-block;
  color: #3C3C3C !important;
  border: 1px solid #F7F7F7;
}
.mail-sign-up .container .mail-sign-up__content .col-2 .email::placeholder, .mail-sign-up .announcement__container .mail-sign-up__content .col-2 .email::placeholder, .mail-sign-up .your-downloads .mail-sign-up__content .col-2 .email::placeholder, .mail-sign-up .basket__container .mail-sign-up__content .col-2 .email::placeholder, .mail-sign-up .checkout__container .mail-sign-up__content .col-2 .email::placeholder, .mail-sign-up .shop-inner__container .mail-sign-up__content .col-2 .email::placeholder, .mail-sign-up .shop-outer__container .mail-sign-up__content .col-2 .email::placeholder, .mail-sign-up .resource-section__container .mail-sign-up__content .col-2 .email::placeholder, .mail-sign-up .resource-categories__container .mail-sign-up__content .col-2 .email::placeholder, .mail-sign-up .product-categories__container .mail-sign-up__content .col-2 .email::placeholder, .mail-sign-up .product-slide__container .mail-sign-up__content .col-2 .email::placeholder {
  color: #3C3C3C !important;
}
.mail-sign-up .container .mail-sign-up__content .col-2 .email:-ms-input-placeholder, .mail-sign-up .announcement__container .mail-sign-up__content .col-2 .email:-ms-input-placeholder, .mail-sign-up .your-downloads .mail-sign-up__content .col-2 .email:-ms-input-placeholder, .mail-sign-up .basket__container .mail-sign-up__content .col-2 .email:-ms-input-placeholder, .mail-sign-up .checkout__container .mail-sign-up__content .col-2 .email:-ms-input-placeholder, .mail-sign-up .shop-inner__container .mail-sign-up__content .col-2 .email:-ms-input-placeholder, .mail-sign-up .shop-outer__container .mail-sign-up__content .col-2 .email:-ms-input-placeholder, .mail-sign-up .resource-section__container .mail-sign-up__content .col-2 .email:-ms-input-placeholder, .mail-sign-up .resource-categories__container .mail-sign-up__content .col-2 .email:-ms-input-placeholder, .mail-sign-up .product-categories__container .mail-sign-up__content .col-2 .email:-ms-input-placeholder, .mail-sign-up .product-slide__container .mail-sign-up__content .col-2 .email:-ms-input-placeholder {
  color: #3C3C3C;
}
.mail-sign-up .container .mail-sign-up__content .col-2 .email::-ms-input-placeholder, .mail-sign-up .announcement__container .mail-sign-up__content .col-2 .email::-ms-input-placeholder, .mail-sign-up .your-downloads .mail-sign-up__content .col-2 .email::-ms-input-placeholder, .mail-sign-up .basket__container .mail-sign-up__content .col-2 .email::-ms-input-placeholder, .mail-sign-up .checkout__container .mail-sign-up__content .col-2 .email::-ms-input-placeholder, .mail-sign-up .shop-inner__container .mail-sign-up__content .col-2 .email::-ms-input-placeholder, .mail-sign-up .shop-outer__container .mail-sign-up__content .col-2 .email::-ms-input-placeholder, .mail-sign-up .resource-section__container .mail-sign-up__content .col-2 .email::-ms-input-placeholder, .mail-sign-up .resource-categories__container .mail-sign-up__content .col-2 .email::-ms-input-placeholder, .mail-sign-up .product-categories__container .mail-sign-up__content .col-2 .email::-ms-input-placeholder, .mail-sign-up .product-slide__container .mail-sign-up__content .col-2 .email::-ms-input-placeholder {
  color: #3C3C3C;
}
.mail-sign-up .container .mail-sign-up__content .clear, .mail-sign-up .announcement__container .mail-sign-up__content .clear, .mail-sign-up .your-downloads .mail-sign-up__content .clear, .mail-sign-up .basket__container .mail-sign-up__content .clear, .mail-sign-up .checkout__container .mail-sign-up__content .clear, .mail-sign-up .shop-inner__container .mail-sign-up__content .clear, .mail-sign-up .shop-outer__container .mail-sign-up__content .clear, .mail-sign-up .resource-section__container .mail-sign-up__content .clear, .mail-sign-up .resource-categories__container .mail-sign-up__content .clear, .mail-sign-up .product-categories__container .mail-sign-up__content .clear, .mail-sign-up .product-slide__container .mail-sign-up__content .clear {
  max-width: 200px;
  margin: 0;
  display: inline-block;
  margin-left: 20px;
}
.mail-sign-up .container .mail-sign-up__content .clear .button, .mail-sign-up .announcement__container .mail-sign-up__content .clear .button, .mail-sign-up .your-downloads .mail-sign-up__content .clear .button, .mail-sign-up .basket__container .mail-sign-up__content .clear .button, .mail-sign-up .checkout__container .mail-sign-up__content .clear .button, .mail-sign-up .shop-inner__container .mail-sign-up__content .clear .button, .mail-sign-up .shop-outer__container .mail-sign-up__content .clear .button, .mail-sign-up .resource-section__container .mail-sign-up__content .clear .button, .mail-sign-up .resource-categories__container .mail-sign-up__content .clear .button, .mail-sign-up .product-categories__container .mail-sign-up__content .clear .button, .mail-sign-up .product-slide__container .mail-sign-up__content .clear .button {
  padding: 7px;
  background-color: #bf5c8a;
}
.mail-sign-up .container .mail-sign-up__content .clear .button:hover, .mail-sign-up .announcement__container .mail-sign-up__content .clear .button:hover, .mail-sign-up .your-downloads .mail-sign-up__content .clear .button:hover, .mail-sign-up .basket__container .mail-sign-up__content .clear .button:hover, .mail-sign-up .checkout__container .mail-sign-up__content .clear .button:hover, .mail-sign-up .shop-inner__container .mail-sign-up__content .clear .button:hover, .mail-sign-up .shop-outer__container .mail-sign-up__content .clear .button:hover, .mail-sign-up .resource-section__container .mail-sign-up__content .clear .button:hover, .mail-sign-up .resource-categories__container .mail-sign-up__content .clear .button:hover, .mail-sign-up .product-categories__container .mail-sign-up__content .clear .button:hover, .mail-sign-up .product-slide__container .mail-sign-up__content .clear .button:hover {
  background-color: #bf4884;
}

@media (max-width: 768px) {
  .mail-sign-up .col-1 {
    width: 100% !important;
  }
  .mail-sign-up .col-2 {
    width: 100% !important;
  }
}
@media (max-width: 425px) {
  .mail-sign-up .email {
    width: 250px !important;
  }
}
.site-footer {
  background-color: #7BB142;
  padding-top: 2.67rem;
  padding-bottom: 2.67rem;
  font-family: "Woodford Bourne", sans-serif;
}
.site-footer a {
  color: #fff;
  text-decoration: none;
  display: block;
}
.site-footer .info-row .col-1 {
  width: calc(16.6666666667% - 23.3333333333px);
  float: left;
  margin-left: 20px;
  margin-bottom: 2.14rem;
}
.site-footer .info-row .col-1 img {
  max-width: 196px;
}
.site-footer .info-row .col-2 {
  text-align: center;
  width: calc(66.6666666667% - 33.3333333333px);
  float: left;
  margin-left: 20px;
  font-size: 24px;
  margin-bottom: 2.14rem;
}
.site-footer .info-row .col-2 p {
  color: white;
}
.site-footer .info-row .col-2 a {
  margin-top: 32px;
  line-height: 1;
}
.site-footer .info-row .col-2 a:first-of-type {
  margin-bottom: 30px;
}
@media (max-width: 990px) {
  .site-footer .info-row .col-2 {
    font-size: 1em;
  }
}
.site-footer .info-row .col-3 {
  width: calc(16.6666666667% - 23.3333333333px);
  float: left;
  margin-left: 20px;
  font-size: 24px;
  text-align: right;
}
.site-footer .info-row .col-3 address {
  color: #fff;
  font-style: normal;
  line-height: 1.55;
}
.site-footer .credit-row {
  padding-top: 64px;
}
.site-footer .credit-row p, .site-footer .credit-row a {
  color: #bad999;
  font-size: 1.25em;
}
.site-footer .credit-row a {
  display: inline-block;
}
.site-footer .credit-row a:hover {
  color: #fff;
}
.site-footer .credit-row .col-1 {
  text-align: left;
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
}
.site-footer .credit-row .col-2, .site-footer .credit-row .col-3 {
  text-align: right;
  width: calc(16.6666666667% - 23.3333333333px);
  float: left;
  margin-left: 20px;
}
@media (max-width: 1200px) {
  .site-footer .credit-row .col-1 {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
  .site-footer .credit-row .col-2 {
    width: calc(20.8333333333% - 24.1666666667px);
    float: left;
    margin-left: 20px;
    text-align: right;
  }
  .site-footer .credit-row .col-3 {
    width: calc(91.6666666667% - 38.3333333333px);
    float: left;
    margin-left: 20px;
    text-align: right;
  }
}
@media (max-width: 1000px) {
  .site-footer .credit-row .col-1 {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
  .site-footer .credit-row .col-2 {
    width: calc(45.8333333333% - 29.1666666667px);
    float: left;
    margin-left: 20px;
    text-align: right;
  }
  .site-footer .credit-row .col-3 {
    width: calc(95.8333333333% - 39.1666666667px);
    float: left;
    margin-left: 20px;
    text-align: right;
  }
}
@media (max-width: 900px) {
  .site-footer .credit-row .col-1, .site-footer .credit-row .col-2, .site-footer .credit-row .col-3 {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    text-align: left;
  }
}

/*@media (min-width: $tablet-m) {
  .site-footer {

    .info-row {
      .col-1 {
        @include grid-shift(1);
        @include grid-column(1.5);
      }

      .col-2 {
        @include grid-shift(1);
        @include grid-column(2.5);
      }

      .col-3 {
        @include grid-shift(2);
        @include grid-column(3);
      }
    }
  }
}*/
.social-link {
  margin: 24px 16px 0 0;
  width: 24px;
  height: auto;
  display: inline-block !important;
}

.message-us {
  width: auto;
  background: #0084FF;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  position: fixed;
  bottom: 0;
  right: 32px;
  z-index: 10000;
}
.message-us img {
  width: 32px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}
.message-us span {
  padding: 0 11px 0 0;
}

.shop-outer {
  padding-top: 0 !important;
}
.shop-outer__product {
  width: calc(25% - 25px);
  float: left;
  margin-left: 20px;
  text-align: center;
  margin-bottom: 40px;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.shop-outer__product:hover {
  -moz-transform: scale(0.98);
  -ms-transform: scale(0.98);
  -o-transform: scale(0.98);
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}
.shop-outer__product:nth-child(4n+4) {
  margin-right: 0;
}
@media (max-width: 900px) {
  .shop-outer__product {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
  .shop-outer__product:nth-child(2n+2) {
    margin-right: 0;
  }
}
@media (max-width: 500px) {
  .shop-outer__product {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-right: 0;
  }
}
.shop-outer__product:hover, .shop-outer__product:hover a {
  color: #BF5C8A;
}
.shop-outer__product-title {
  font-size: 1.5em;
  margin: 10px auto;
  overflow: hidden;
  height: 54px;
}
.shop-outer__product-title a {
  text-decoration: none;
}
.shop-outer__product-image {
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%;
  height: 267px;
  min-height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.shop-title, .your-downloads h1 {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  text-align: center;
}

.shop-inner__image {
  width: calc(41.6666666667% - 28.3333333333px);
  float: left;
  margin-left: 20px;
}
.shop-inner__image img {
  width: 100%;
  height: auto;
}
.shop-inner__info {
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
  left: calc(8.3333333333% - 21.6666666667px + 20px);
  position: relative;
}
.shop-inner__info-title {
  color: #BF5C8A;
  font-family: "Playfair Display", serif;
}
.shop-inner__info-price, .shop-inner__variant-price {
  text-align: center;
}
.shop-inner__info-price strong, .shop-inner__variant-price strong {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
}
.shop-inner__info-button {
  padding: 20px;
  background: #DEE4EE;
}
.shop-inner__info-button .shop-inner__info-price, .shop-inner__info-button .shop-inner__variant-price {
  width: calc(33.3333333333% - 26.6666666667px);
  float: left;
  margin-left: 20px;
}
.shop-inner__info-button .shop-inner__info-quantity {
  width: calc(16.6666666667% - 23.3333333333px);
  float: left;
  margin-left: 20px;
  text-align: center;
  margin-bottom: 0;
}
.shop-inner__info-button button {
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
  padding: 6px 0;
}
.shop-inner__variant {
  width: 100%;
}
.shop-inner__variant-title {
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
}
.shop-inner__variant-price {
  width: calc(33.3333333333% - 26.6666666667px);
  float: left;
  margin-left: 20px;
}
.shop-inner__variant-quantity {
  width: calc(16.6666666667% - 23.3333333333px);
  float: left;
  margin-left: 20px;
}
@media (max-width: 1200px) {
  .shop-inner__image {
    width: calc(66.6666666667% - 33.3333333333px);
    float: left;
    margin-left: 20px;
  }
  .shop-inner__info {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
}

.snippet-special-offer {
  background-color: #dee4ee;
  padding: 20px 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.snippet-special-offer h4 {
  margin-bottom: 10px;
}
.snippet-special-offer p {
  margin-bottom: 10px;
}

.basket {
  padding-top: 0 !important;
}
.basket.standalone {
  padding-top: 6em !important;
  min-height: 600px;
}
.basket p {
  text-align: center;
}
.basket__heading, .checkout__heading {
  color: #516FA3;
  font-family: "Playfair Display", serif;
  text-align: center;
  font-size: 2.5em;
  margin: 0 auto 40px auto;
}
.basket__container, .checkout__container {
  background: #DEE4EE;
  padding: 32px;
  max-width: 800px;
}
.basket__product, .basket__product-header {
  margin-bottom: 20px;
  padding: 20px;
  background: #eff2f7;
}
.basket__product-image {
  width: calc(16.6666666667% - 23.3333333333px);
  float: left;
  margin-left: 20px;
  overflow: hidden;
}
.basket__product-image div {
  width: 100%;
  height: 160px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.basket__product-title {
  width: calc(50% - 30px);
  float: left;
  margin-left: 20px;
}
.basket__product-quantity {
  width: calc(16.6666666667% - 23.3333333333px);
  float: left;
  margin-left: 20px;
}
.basket__product-quantity input {
  max-width: 96px;
  display: inline-block;
}
.basket__product-price {
  width: calc(16.6666666667% - 23.3333333333px);
  float: left;
  margin-left: 20px;
}
.basket__product-button {
  width: calc(66.6666666667% - 33.3333333333px);
  float: left;
  margin-left: 20px;
}
.checkout button {
  margin-top: 15px;
}
.checkout__stripe-element {
  background: #fff;
  padding-top: 11px;
  padding-bottom: 11px;
}
.checkout__stripe-element.attention {
  border: 1px solid crimson;
}
.checkout__stripe-element.expiry {
  width: calc(25% - 25px);
  float: left;
  margin-left: 20px;
}
.checkout__stripe-element.cvc {
  width: calc(16.6666666667% - 23.3333333333px);
  float: left;
  margin-left: 20px;
  width: 81px !important;
}
.checkout__stripe-element.number {
  background-position: 150% center;
  background-repeat: no-repeat;
  background-size: 51px 32px;
  -moz-transition: background-position 1s ease-out;
  -o-transition: background-position 1s ease-out;
  -webkit-transition: background-position 1s ease-out;
  transition: background-position 1s ease-out;
  background-image: url("../images/cards/unknown.png");
}
.checkout__stripe-element.number[data-card_type=visa] {
  background-image: url("../images/cards/visa.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=mastercard] {
  background-image: url("../images/cards/mastercard.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=amex] {
  background-image: url("../images/cards/amex.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=maestro] {
  background-image: url("../images/cards/maestro.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=switch] {
  background-image: url("../images/cards/visa.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=solo] {
  background-image: url("../images/cards/visa.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=eurocard] {
  background-image: url("../images/cards/unknown.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=visa_electron] {
  background-image: url("../images/cards/visa_electron.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=jcb] {
  background-image: url("../images/cards/jcb.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=discover] {
  background-image: url("../images/cards/discover.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=diners] {
  background-image: url("../images/cards/diners.png");
  background-position: 98% center;
}
.checkout__stripe-element.number[data-card_type=unionpay] {
  background-image: url("../images/cards/unionpay.png");
  background-position: 98% center;
}

.header-basket, .header-basket__desktop {
  position: relative;
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding: 0 0 0 40px;
  top: 18px;
}
.header-basket__desktop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-basket__icon {
  display: inline-block;
  background-image: url("../images/basket.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.header-basket__count {
  width: 100%;
  text-align: center;
  display: block;
  position: relative;
  top: 4px;
}
.header-basket.mobile, .mobile.header-basket__desktop {
  padding: 0;
  top: 0;
}

.large-form {
  width: 47% !important;
  float: right;
  margin-top: 40px;
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

.product-categories {
  margin-bottom: 60px;
}
.product-categories__category--selected {
  text-decoration: underline;
  color: #BF5C8A;
}

.your-downloads {
  max-width: 800px;
  text-align: center;
}
.your-downloads .resource-section__header, .your-downloads .resource-box {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  text-align: left;
}
.your-downloads h1 {
  margin-right: 0;
}

.announcement {
  padding-top: 0 !important;
}
.announcement__container {
  background: #BF5C8A;
  border: 4px solid #a74170;
  color: #fff;
  padding: 40px;
}
.announcement__container h2 {
  font-size: 2em;
  margin: 0 0 10px 0;
}
.announcement__container a {
  color: #fff;
}

.robin-note {
  background: #b8e6ec;
}
.robin-note p {
  font-family: "Woodford Bourne", sans-serif;
}
.robin-note__title {
  text-align: center;
}
.robin-note__title h3 {
  font-size: 3em;
  color: #35B7C9;
  font-family: "Playfair Display", serif;
}
@media (max-width: 450px) {
  .robin-note__title h3 {
    font-size: 2.2em;
  }
}
.robin-note__page {
  max-width: 700px;
  background: #fff;
  margin: 0 auto;
  padding: 40px;
  position: relative;
  font-size: 1.2em;
}
.robin-note__page p:first-of-type {
  font-size: 1.1em;
  font-weight: 700;
}
.robin-note__robin {
  width: 100%;
}
.robin-note__robin-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
  width: 100px;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
}
@media (max-width: 450px) {
  .robin-note__robin-image {
    display: block;
  }
}
.robin-note__robin-text {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
}
.robin-note__robin-text p {
  margin-bottom: 0;
}
@media (max-width: 450px) {
  .robin-note__robin-text {
    margin-left: 0;
    margin-top: 20px;
    display: block;
  }
}

.leafy-quote {
  background-image: url("../../../data/cms_images/leafy-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
.leafy-quote p {
  font-family: "Woodford Bourne", sans-serif;
}
.leafy-quote__container {
  padding: 200px;
}
@media (max-width: 850px) {
  .leafy-quote__container {
    padding: 100px;
  }
}
@media (max-width: 600px) {
  .leafy-quote__container {
    padding: 50px;
  }
}
.leafy-quote__quote {
  max-width: 700px;
  margin: auto;
  color: #fff;
  font-size: 1.8em;
  line-height: 1.8;
  position: relative;
}
@media (max-width: 600px) {
  .leafy-quote__quote {
    font-size: 1.3em;
  }
}
.leafy-quote__overlay {
  background: grey;
  opacity: 0.5;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.enabling-churches {
  text-align: center;
}
@media (max-width: 800px) {
  .enabling-churches__container {
    padding: 20px;
  }
}
.enabling-churches__title h3 {
  font-family: "Playfair Display", serif;
  color: #5CAA75;
  font-size: 3em;
  margin-bottom: 40px;
}
@media (max-width: 425px) {
  .enabling-churches__title h3 {
    font-size: 2.2em;
  }
}
.enabling-churches__intro {
  max-width: 650px;
  margin: auto;
}
.enabling-churches__intro p {
  font-family: "Woodford Bourne", sans-serif;
  font-size: 1.3em;
}
.enabling-churches__diagram {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 650px;
  height: 500px;
  margin: auto;
}
@media (max-width: 750px) {
  .enabling-churches__diagram {
    width: 500px;
    height: 385px;
  }
}
@media (max-width: 550px) {
  .enabling-churches__diagram {
    width: 100%;
    height: 300px;
    margin: 0;
  }
}
@media (max-width: 400px) {
  .enabling-churches__diagram {
    width: 100%;
    height: 250px;
    margin: 0;
  }
}
@media (max-width: 400px) {
  .enabling-churches__diagram {
    width: 100%;
    height: 250px;
    margin: 0;
  }
}
.enabling-churches__outro h3 {
  margin-top: 40px;
  color: #737373;
}
@media (max-width: 750px) {
  .enabling-churches__outro h3 {
    font-size: 1.5em;
  }
}
@media (max-width: 550px) {
  .enabling-churches__outro h3 {
    font-size: 1.3em;
  }
}

.bible-quote {
  background: #F7F6F5;
  padding: 40px 0 !important;
}
.bible-quote__container {
  max-width: 570px;
  margin: auto;
  padding: 0 30px;
}
.bible-quote__bible {
  background-image: url("../../../data/cms_images/bible.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 50px;
  width: 80px;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 650px) {
  .bible-quote__bible {
    height: 40px;
  }
}
.bible-quote h3 {
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px;
  color: #000000;
  font-size: 2.5em;
}
@media (max-width: 650px) {
  .bible-quote h3 {
    font-size: 2em;
  }
}
@media (max-width: 425px) {
  .bible-quote h3 {
    font-size: 1.8em;
  }
}
@media (max-width: 370px) {
  .bible-quote h3 {
    margin-top: 10px;
  }
}
.bible-quote__quote {
  margin-top: 20px;
  margin-left: 10px;
  font-size: 1.3em;
}
@media (max-width: 650px) {
  .bible-quote__quote {
    font-size: 1.1em;
  }
}
@media (max-width: 370px) {
  .bible-quote__quote {
    margin-top: 10px;
    margin-left: 20px;
  }
}
.bible-quote__quote p {
  margin: 0;
}

.text-block-blue {
  background: #b8e6ec;
}
.text-block-blue__container {
  max-width: 700px;
  margin: auto;
}
@media (max-width: 800px) {
  .text-block-blue__container {
    padding: 0 40px;
  }
}
.text-block-blue__title {
  text-align: center;
}
.text-block-blue__title h3 {
  font-size: 3em;
  font-family: "Playfair Display", serif;
}
@media (max-width: 425px) {
  .text-block-blue__title h3 {
    font-size: 2.2em;
  }
}
.text-block-blue__body ul {
  list-style: none;
}
.text-block-blue__body li {
  margin-left: 2em;
}
.text-block-blue__body li::before {
  content: "—";
  display: inline-block;
  width: 1em;
  margin-right: 1em;
  margin-left: -2em;
}
.text-block-white {
  background: #fff;
}
.text-block-white__container {
  max-width: 700px;
  margin: auto;
}
@media (max-width: 800px) {
  .text-block-white__container {
    padding: 0 40px;
  }
}
.text-block-white__title {
  text-align: center;
}
.text-block-white__title h3 {
  font-size: 3em;
  font-family: "Playfair Display", serif;
  color: #5CAA75;
}
@media (max-width: 425px) {
  .text-block-white__title h3 {
    font-size: 2.2em;
  }
}
.text-block-white__body ul {
  list-style: none;
}
.text-block-white__body li {
  margin-left: 2em;
}
.text-block-white__body li::before {
  content: "—";
  display: inline-block;
  width: 1em;
  margin-right: 1em;
  margin-left: -2em;
}

.full-width-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 800px;
  width: 100%;
}
@media (max-width: 1400px) {
  .full-width-image {
    height: 600px;
  }
}

.roadmap-growth {
  padding: 0 !important;
}
.roadmap-growth__text {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 6em 1.5em !important;
}
@media (max-width: 1100px) {
  .roadmap-growth__text {
    width: 100%;
  }
}
.roadmap-growth__text h3 {
  font-family: "Playfair Display", serif;
  color: #5CAA75;
  text-align: center;
  font-size: 3em;
}
@media (max-width: 425px) {
  .roadmap-growth__text h3 {
    font-size: 2.2em;
  }
}
.roadmap-growth__text p {
  text-align: center;
  font-size: 1.2em;
  margin: 40px 0;
}
.roadmap-growth__text ol {
  padding: 0 40px;
  list-style: none;
  counter-reset: li;
}
.roadmap-growth__text li {
  font-family: "Playfair Display", serif;
  color: #737373;
  font-size: 2em;
  line-height: 1.3;
  counter-increment: li;
}
@media (max-width: 425px) {
  .roadmap-growth__text li {
    font-size: 1.5em;
  }
}
.roadmap-growth__text li::before {
  content: counter(li);
  color: #35B7C9;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.roadmap-growth__text-container {
  max-width: 60%;
  float: right;
  vertical-align: middle;
  padding: 0 40px;
}
@media (max-width: 1700px) {
  .roadmap-growth__text-container {
    max-width: 75%;
  }
}
@media (max-width: 1500px) {
  .roadmap-growth__text-container {
    max-width: 85%;
  }
}
@media (max-width: 1350px) {
  .roadmap-growth__text-container {
    max-width: 100%;
  }
}
@media (max-width: 1100px) {
  .roadmap-growth__text-container {
    max-width: 80%;
    margin: auto;
    float: none;
  }
}
@media (max-width: 650px) {
  .roadmap-growth__text-container {
    max-width: 100%;
  }
}
@media (max-width: 425px) {
  .roadmap-growth__text-container {
    padding: 0;
  }
}
.roadmap-growth__image {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding: 0 20px;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url("../../../data/cms_images/feet.png");
}
@media (max-width: 1100px) {
  .roadmap-growth__image {
    width: 100%;
  }
}
.roadmap-growth__quote {
  min-width: 60%;
  max-width: 60%;
  font-family: "Woodford Bourne", sans-serif;
  font-size: 2.5em;
  float: left;
  padding: 6em 50px !important;
  vertical-align: middle;
  position: relative;
  top: 9%;
  color: #fff;
  line-height: 1.2;
}
@media (max-width: 1700px) {
  .roadmap-growth__quote {
    min-width: 75%;
    max-width: 75%;
  }
}
@media (max-width: 1500px) {
  .roadmap-growth__quote {
    min-width: 85%;
    max-width: 85%;
  }
}
@media (max-width: 1350px) {
  .roadmap-growth__quote {
    min-width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 1100px) {
  .roadmap-growth__quote {
    max-width: 80%;
    min-width: 80%;
    margin: auto;
    float: none;
  }
}
@media (max-width: 650px) {
  .roadmap-growth__quote {
    max-width: 100%;
    min-width: 100%;
  }
}
@media (max-width: 425px) {
  .roadmap-growth__quote {
    font-size: 2.2em;
    top: 0;
  }
}
.roadmap-growth__quote p {
  margin-bottom: 40px;
}
.roadmap-growth__quote p:last-of-type {
  margin-bottom: 0;
}
.roadmap-growth__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: #000000;
  opacity: 0.5;
}

.begin-your-journey {
  padding-bottom: 0 !important;
}
.begin-your-journey__container {
  max-width: 700px;
  margin: auto;
}
@media (max-width: 800px) {
  .begin-your-journey__container {
    padding: 0 40px;
  }
}
@media (max-width: 500px) {
  .begin-your-journey__container {
    padding: 0;
  }
}
.begin-your-journey__title {
  text-align: center;
}
.begin-your-journey__title h3 {
  font-size: 3em;
  font-family: "Playfair Display", serif;
  color: #5CAA75;
  line-height: 1;
}
@media (max-width: 425px) {
  .begin-your-journey__title h3 {
    font-size: 2.2em;
  }
}
.begin-your-journey__body {
  text-align: center;
}
@media (max-width: 500px) {
  .begin-your-journey__body {
    text-align: left;
  }
}
.begin-your-journey__body p {
  text-align: left;
  margin: 40px 0;
}
.begin-your-journey__body a {
  color: #35B7C9;
  display: inline-block;
  font-family: "Woodford Bourne", sans-serif;
  font-size: 1.8em;
  text-decoration: none;
  vertical-align: middle;
  line-height: 1;
  margin: 20px 0;
}
@media (max-width: 700px) {
  .begin-your-journey__body a {
    font-size: 1.5em;
  }
}
@media (max-width: 600px) {
  .begin-your-journey__body a {
    font-size: 1.2em;
  }
}
@media (max-width: 500px) {
  .begin-your-journey__body a {
    font-size: 1em;
  }
}
.begin-your-journey__body a span {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 25px;
  width: 30px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.begin-your-journey__email span {
  background-image: url("../../../data/cms_images/email.svg");
}
.begin-your-journey__telephone span {
  background-image: url("../../../data/cms_images/phone.svg");
}
.begin-your-journey__image {
  background-image: url("../../../data/cms_images/plant.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 400px;
  width: 100%;
}

.eie-feature__container {
  max-width: 990px;
  margin: 0 auto;
}
.eie-feature__quote {
  background-image: url("../../../data/cms_images/leafy-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  position: relative;
  padding: 60px;
  width: 60%;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 800px) {
  .eie-feature__quote {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .eie-feature__quote {
    padding: 40px;
  }
}
@media (max-width: 320px) {
  .eie-feature__quote {
    padding: 30px;
  }
}
.eie-feature__overlay {
  background-color: #737373;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.eie-feature__text {
  position: relative;
  z-index: 10;
  font-family: "Woodford Bourne", sans-serif;
  font-size: 2em;
  line-height: 1.2;
}
.eie-feature__text ul {
  list-style: none;
}
.eie-feature__text li {
  margin-left: 1em;
  margin-bottom: 0;
}
.eie-feature__text li::before {
  content: "—";
  display: inline-block;
  width: 1em;
  margin-right: 0em;
  margin-left: -1em;
}
@media (max-width: 900px) {
  .eie-feature__text {
    font-size: 1.8em;
  }
  .eie-feature__text p {
    margin-bottom: 30px;
  }
}
@media (max-width: 425px) {
  .eie-feature__text {
    font-size: 1.5em;
  }
}
.eie-feature__button {
  padding: 10px 20px;
  background-color: #fff;
  color: #737373;
  font-weight: 700;
  text-decoration: none;
  font-size: 0.8em;
}
.eie-feature__button:hover {
  cursor: pointer;
}
.eie-feature__eie {
  width: 40%;
  display: inline-block;
  background-color: #7CD69A;
  vertical-align: top;
  padding: 14% 0;
}
.eie-feature__eie h2 {
  font-family: "Playfair Display", serif;
  color: #fff;
  line-height: 1;
  text-align: center;
  font-size: 3em;
  margin-bottom: 10px;
}
.eie-feature__eie .inspiring {
  font-size: 4em;
}
.eie-feature__eie .equipping {
  font-size: 3.55em;
}
@media (max-width: 900px) {
  .eie-feature__eie h2 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  .eie-feature__eie .inspiring {
    font-size: 3.3em;
  }
  .eie-feature__eie .equipping {
    font-size: 2.9em;
  }
}
@media (max-width: 800px) {
  .eie-feature__eie {
    width: 100%;
    padding: 10% 30%;
  }
  .eie-feature__eie h2 {
    font-size: 3em;
    margin-bottom: 10px;
  }
  .eie-feature__eie .inspiring {
    font-size: 4.1em;
  }
  .eie-feature__eie .equipping {
    font-size: 3.7em;
  }
}
@media (max-width: 650px) {
  .eie-feature__eie {
    padding: 18% 26%;
  }
  .eie-feature__eie h2 {
    font-size: 3em;
  }
  .eie-feature__eie .inspiring {
    font-size: 4.1em;
  }
  .eie-feature__eie .equipping {
    font-size: 3.6em;
  }
}
@media (max-width: 550px) {
  .eie-feature__eie {
    padding: 30% 20%;
  }
}
@media (max-width: 500px) {
  .eie-feature__eie {
    padding: 30% 19%;
  }
}
@media (max-width: 425px) {
  .eie-feature__eie {
    padding: 30% 19%;
  }
  .eie-feature__eie h2 {
    font-size: 2.5em;
  }
  .eie-feature__eie .inspiring {
    font-size: 3.4em;
  }
  .eie-feature__eie .equipping {
    font-size: 3.05em;
  }
}
@media (max-width: 425px) {
  .eie-feature__eie h2 {
    font-size: 2.2em;
  }
  .eie-feature__eie .inspiring {
    font-size: 3em;
  }
  .eie-feature__eie .equipping {
    font-size: 2.7em;
  }
}
@media (max-width: 320px) {
  .eie-feature__eie {
    padding: 49% 12%;
  }
}
.eie-feature__underline {
  background-color: #BA558D;
  height: 10px;
  width: 66%;
  margin: 50px auto 0;
}
@media (max-width: 900px) {
  .eie-feature__underline {
    width: 65%;
  }
}
@media (max-width: 800px) {
  .eie-feature__underline {
    width: 270px;
    margin: 50px auto 0;
  }
}
@media (max-width: 500px) {
  .eie-feature__underline {
    width: 100%;
  }
}

.cookie-popup {
  background: #fff;
  width: 100%;
  max-width: 480px;
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 9999;
  padding: 40px 40px 50px 40px;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  border: 2px solid #7BB142;
}
.cookie-popup:after {
  content: "";
  background: #7BB142;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  left: 0;
}
.cookie-popup .button p {
  margin-bottom: 0;
}
@media (max-width: 780px) {
  .cookie-popup {
    max-width: calc(100% - 40px);
    padding: 30px 30px 40px 30px;
    top: 20px;
    bottom: unset;
  }
}
@media (max-width: 400px) {
  .cookie-popup .button {
    width: 100%;
  }
}