.leafy-quote{
    background-image: url("../../../data/cms_images/leafy-bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  position: relative;

  p{
    font-family: $header-font;
  }
  &__container{
    padding: 200px;

    @media (max-width: 850px) {
      padding: 100px;
    }

    @media (max-width: 600px) {
      padding: 50px;
    }
  }

  &__quote{
    max-width: 700px;
    margin: auto;
    color: $white;
    font-size: 1.8em;
    line-height: 1.8;
    position: relative;

    @media (max-width: 600px) {
      font-size: 1.3em;
    }
  }

  &__overlay{
    background: grey;
    opacity: 0.5;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}