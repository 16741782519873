.contact-section {
	padding-top: $base-space-unit;
	padding-bottom: $base-space-unit * 2;
	background-color: $new-blue;
}

.contact-section__links {
	text-align: center;

	a, span {
		display: block;
		margin-bottom: 40px;
		text-decoration: none;
		color: $light-blue;
		font-family: $header-font;
		font-size: 20px;
	}

	i {
		display: inline-block;
		height: 25px;
		width: 30px;
		vertical-align: middle;
		margin-right: 1.4em;
	}
}


.contact-section__form {
	max-width: 555px;
	margin: 64px auto 0;


  .form-error
  {
	background-color: $form-error;
  }
}