.robin-note {
  background: $eie-lightblue;

  p{
    font-family: $header-font;
  }

  &__container {

  }

  &__title {
    text-align: center;
    h3 {
      font-size: 3em;
      color: $eie-darkblue;
      font-family: $playfair;

      @media (max-width: 450px) {
        font-size: 2.2em;
      }
    }
  }

  &__page {
    max-width: 700px;
    background: $white;
    margin: 0 auto;
    padding: 40px;
    position: relative;
    font-size: 1.2em;

    & p:first-of-type {
      font-size: 1.1em;
      font-weight: 700;
    }
  }

  &__robin {
    width: 100%;

    &-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100px;
      width: 100px;
      display: inline-block;
      border-radius: 50%;
      vertical-align: middle;

      @media (max-width: 450px) {
        display: block;
      }
    }

    &-text {
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle;

      p {
        margin-bottom: 0;
      }

      @media (max-width: 450px) {
        margin-left: 0;
        margin-top: 20px;
        display: block;
      }
    }

  }
}
